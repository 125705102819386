<template></template>

<script setup>
import { useAuthenticate } from '@/application/authenticate';
import router from '@/infra/router';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

const route = useRoute();
const { authenticateFromToken } = useAuthenticate();

onMounted(async () => {
  await authenticateFromToken({ token: route.params.token, user_id: route.params.user_id });
  router.replace({ name: 'ProjectList' });
});

</script>