<template>
  <div class="p-6">
    <a-list item-layout="vertical" :data-source="screens"
      :grid="{ gutter: [24, 24], xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }">
      <template #renderItem="{ item }">
        <a-list-item :key="item.id">
          <router-link :to="{ name: 'ScreenStoreDetails', params: { id: item.id } }">
            <div
              class="flex flex-col border border-gray-200 rounded-lg overflow-hidden transition-transform duration-200 hover:scale-105 hover:shadow-lg">
              <div class="h-48 flex justify-center items-center bg-gray-100">
                <span class="text-gray-400">No image</span>
              </div>
              <div class="p-4">
                <h3 class="text-lg font-semibold mb-2 text-gray-800">{{ item.title }}</h3>
                <p class="text-sm text-gray-600">{{ item.description }}</p>
              </div>
            </div>
          </router-link>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script setup>
import { useStoreStorage } from '@/services/storageAdapter';
import { onMounted } from 'vue';

const { loadAllPublishedScreens, screens } = useStoreStorage();

onMounted(() => {
  loadAllPublishedScreens();
});

</script>
