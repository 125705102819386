import { useCustomWidgetStorage, useUserStorage } from "../services/storageAdapter";
import { CustomWidgetStorageService, UserStorageService, WidgetService } from "./ports";
import { useWidget } from "@/services/widgetAdapter";

export function useUpdateCustomWidget() {
  const userStorage: UserStorageService = useUserStorage();
  const customWidgetStorage: CustomWidgetStorageService = useCustomWidgetStorage();
  const widgetService: WidgetService = useWidget();
  let widget = null;

  async function update(widgetId: UniqueId, name: string, content: string | null): Promise<void> {
    const userId = userStorage.user?.value.id;

    if (!userId) throw Error('User not found');

    widget = await widgetService.updateWidget(widgetId, name, content);
    customWidgetStorage.addCustomWidgets([widget]);
  }

  return {
    widget: widget,
    update,
  };
}