<template></template>

<script setup>
import { onMounted } from 'vue';
import router from '@/infra/router';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useVerifyUserEmail } from '@/application/verifyUserEmail';

const route = useRoute();
const $toast = useToast();
const { verify } = useVerifyUserEmail();

onMounted(async () => {
    if (!route.query.token) {
        $toast.error('Link is invalid');
        return;
    }

    try {
        await verify(route.query.token);
        $toast.success('Email verified successfully');
    } catch (error) {
        $toast.error('Verification failed');
    } finally {
        router.replace({ name: "UserProfile" });
    }
});
</script>
