import PushEvent from "@/domain/event/PushEvent";
import { EventService } from "../application/ports";
import eventAPI from "@/infra/api/events";
import Event from "@/domain/event/Event";

export function useEvent(): EventService {
    const sendEvent = (event: Event, api_token: string) => {
        return eventAPI.sendEvent(event, api_token);
    };
    const sendPushNotification = (event: PushEvent, api_token: string) => {
        return eventAPI.sendPushNotification(event, api_token);
    };
    const getActiveUsers = () => {
        return eventAPI.getActiveUsers();
    };
    const getActiveUserEventLog = (user_id: UniqueId) => {
        return eventAPI.getActiveUserEventLog(user_id);
    };
    return {
        sendEvent,
        sendPushNotification,
        getActiveUsers,
        getActiveUserEventLog
    };
}