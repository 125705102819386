import widgetAPI from "@/infra/api/widgets";
import { WidgetService } from "@/application/ports";

export function useWidget(): WidgetService {
    const getAllCustomWidgets = () => {
        return widgetAPI.getAllCustomWidgets();
      };
      const createCustomWidget = (name: string, content: string | null) => {
        return widgetAPI.createCustomWidget(name, content);
      };
      const updateWidget = (widget_id: UniqueId, name: string, content: string | null) => {
        return widgetAPI.updateWidget(widget_id, name, content);
      };
      const deleteWidget = (widget_id: UniqueId) => {
        return widgetAPI.deleteWidget(widget_id);
      };

    return {
        getAllCustomWidgets,
        createCustomWidget,
        updateWidget,
        deleteWidget,
    };
}