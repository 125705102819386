import { Module } from 'vuex';

interface State {
  buildInfo:  BuildInfo|null;
}

const buildInfoModule: Module<State, any> = {
  namespaced: true,
  actions: {},
  state: (): State => ({
    buildInfo: null,
  }),
  mutations: {
    setBuildInfo(state: State, buildInfo: BuildInfo | null) {
      state.buildInfo = buildInfo;
    },
  },
  getters: {
    buildInfo(state: State): BuildInfo | null {
      return state.buildInfo;
    },
  },
};

export default buildInfoModule;
