import { Module } from 'vuex';
import { CustomWidget } from '@/domain/custom_widget/CustomWidget';

interface State {
  widgetMap: Map<UniqueId, CustomWidget>;
}

const customWidgetModule: Module<State, any> = {
  namespaced: true,
  state: (): State => ({
    widgetMap: new Map(),
  }),
  actions: {},
  mutations: {
    addWidget(state: State, widget: CustomWidget) {
      state.widgetMap.set(widget.id, widget);
    },
  },
  getters: {
    widgetsMap(state: State): Map<UniqueId, CustomWidget> {
      return state.widgetMap;
    },
  },
};

export default customWidgetModule;
