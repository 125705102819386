<template>
  <div>
    <div class="flex pb-2">
      <a-input name="name" type="text" v-model:value="newScreenName" placeholder="New Screen" class="mr-2" />
      <a-button @click="handleSave" type="primary">Save</a-button>
    </div>

    <a-table v-if="project" :data-source="project.screens" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }">
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div class="p-2">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            class="w-48 mb-2" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(confirm)" />
          <a-button type="primary" size="small" class="w-24 mr-2" @click="handleSearch(confirm)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" class="w-24" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <div class="editable-cell">
            <div v-if="editableScreens[record.id]" class="editable-cell-input-wrapper">
              <a-input v-model:value="editableScreens[record.id].name" @pressEnter="handleUpdate(record)" />
              <check-outlined class="editable-cell-icon-check" @click="handleUpdate(record)" />
            </div>
            <div v-else class="editable-cell-text-wrapper">
              <router-link v-if="record.name" :to="{ name: 'Constructor', params: { screen_id: record.project_id, screen_id: record.id } }">
                {{ record.name }}
              </router-link>
              <edit-outlined class="editable-cell-icon" @click="edit(record)" />
            </div>
          </div>
        </template>

        <template v-if="column.key === 'links'">
          <router-link v-if="record.name" :to="{ name: 'ScreenConfig', params: { project_id: record.project, screen_id: record.id } }">
            Config
          </router-link>
        </template>

        <template v-if="column.key === 'action'">
          <a-button @click="handleRemove(record.id)" class="mr-2">Remove</a-button>
          <a-button @click="handleClone(record)">Clone</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { SearchOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useCreateScreen } from '@/application/createScreen';
import { useProjectStorage } from '@/services/storageAdapter';
import { useDeleteScreen } from '@/application/deleteScreen';
import { useUpdateScreen } from '@/application/updateScreen';

const route = useRoute();
const { loadAllProjects, loadScreensByProjectID, getById } = useProjectStorage();
const { create } = useCreateScreen();
const { remove } = useDeleteScreen();
const { update } = useUpdateScreen();
// Refs
const newScreenName = ref('');
const editableScreens = ref({});
const project_id = computed(() => route.params.project_id);
const project = getById(project_id.value);
// Columns for the table
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase())
  },
  {
    title: 'Links',
    dataIndex: 'links',
    key: 'links',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
  }
];

// Methods
const handleSave = async () => {
  await create(project_id.value, newScreenName.value, null);
};

const handleClone = async (screen) => {
  await create(
    project_id.value,
    `${screen.name} copy`,
    { config: screen.config, nestedComponents: screen.nestedComponents }
  );
};

const handleRemove = async (screen_id) => {
  await remove(project_id.value, screen_id)
};

const edit = (screen) => {
  editableScreens.value[screen.id] = {
    original_name: screen.name,
    ...screen,
  };
};

const handleUpdate = async (screen) => {
  const updated = editableScreens.value[screen.id];
  await update(project_id.value, screen.id, updated.name, {nestedComponents: updated.nestedComponents} )
  delete editableScreens.value[screen.id];
};

const handleSearch = (confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters({ confirm: true });
};


onMounted(async () => {
  await loadAllProjects();
  await loadScreensByProjectID(project_id.value);
});

</script>

<style scoped>
.editable-cell {
  position: relative;
}

.editable-cell .editable-cell-input-wrapper,
.editable-cell .editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell .editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell .editable-cell-icon,
.editable-cell .editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell .editable-cell-icon {
  margin-top: 4px;
  display: none;
}

.editable-cell .editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell .editable-cell-icon:hover,
.editable-cell .editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>