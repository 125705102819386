import { Module } from 'vuex';
import Project from '@/domain/project/Project';
import User from '@/domain/user/User';
import { Screen } from '@/domain/project/ProjectTypes';

interface State {
  projectsMap: Map<UniqueId, Project>;
}

const projectModule: Module<State, any> = {
  namespaced: true,
  state: (): State => ({
    projectsMap: new Map(),
  }),
  mutations: {
    addProject(state: State, project: Project) {
      state.projectsMap.set(project.id, project);
    },
    setProjectConfigs(state: State, { projectId, configs }: { projectId: UniqueId; configs: Config[] }) {
      const project = state.projectsMap.get(projectId);
      if (!project) {
        console.warn(`[setProjectConfigs] Project with ID ${projectId} not found`);
        return;
      }
      project.configs = configs;
      state.projectsMap.set(projectId, project);
    },
    setProjectCollaborators(state: State, { projectId, collaborators }: { projectId: UniqueId; collaborators: User[] }) {
      const project = state.projectsMap.get(projectId);
      if (!project) {
        console.warn(`[setProjectCollaborators] Project with ID ${projectId} not found`);
        return;
      }
      project.collaborators = collaborators;
      state.projectsMap.set(projectId, project);
    },
    setProjectScreens(state: State, { projectId, screens }: { projectId: UniqueId; screens: Screen[] }) {
      const project = state.projectsMap.get(projectId);
      if (!project) {
        console.warn(`[setProjectScreens] Project with ID ${projectId} not found`);
        return;
      }
      project.screens = screens;
      state.projectsMap.set(projectId, project);
    },
    deleteProjectCollaborator(state: State, { projectId, inviteeId }: { projectId: UniqueId; inviteeId: UniqueId }) {
      const project = state.projectsMap.get(projectId);
      if (!project) {
        console.warn(`[deleteProjectCollaborator] Project with ID ${projectId} not found`);
        return;
      }
      project.collaborators = project.collaborators?.filter(user => user.id !== inviteeId) ?? null;
      state.projectsMap.set(projectId, project);
    },
  },
  getters: {
    projectsMap(state: State): Map<UniqueId, Project> {
      return state.projectsMap;
    },
  },
};

export default projectModule;
