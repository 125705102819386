import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import { ProjectService, ProjectStorageService, UserStorageService } from "./ports";
import { useProject } from "@/services/projectAdapter";

export function useDeleteScreen() {
  const userStorage: UserStorageService = useUserStorage();
  const projectService: ProjectService = useProject();
  const projectStorage: ProjectStorageService = useProjectStorage();
  let screen = null;


  async function remove(project_id: UniqueId, screen_id: UniqueId): Promise<void> {
    const userId = userStorage.user?.value.id;

    if (!userId) throw Error('User not found');
    screen = await projectService.deleteScreen(screen_id);
    projectStorage.deleteScreenById(project_id, screen_id);
  }

  return {
    screen: screen,
    remove,
  };
}