import { createApp } from 'vue';
import App from './App.vue';
import router from './infra/router';
import store from './infra/store';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import draggable from 'vuedraggable';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import './../../common/assets/css/helper.css';

let requireCommonComponent = require.context(
  './../../common/components/',
  true,
  /[A-Z]\w+\.(vue|js)$/
);

const requireComponent = require.context(
  './components',
  true,
  /[A-Z]\w+\.(vue|js)$/
);

const components = requireComponent.keys();
const commonComponents = requireCommonComponent.keys();

const app = createApp(App);
app.component('draggable-component', draggable);

commonComponents.forEach((fileName) => {
  const componentConfig = requireCommonComponent(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  );

  app.component(
    componentConfig.default.name || componentName,
    componentConfig.default || componentConfig
  );
});

components.forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  );

  app.component(
    componentConfig.default.name || componentName,
    componentConfig.default || componentConfig
  );
});

app.directive('analytics', (el, params) => {
  el.setAttribute('analytics', JSON.stringify(params.value));
});

app
  .use(router)
  .use(store)
  .use(ToastPlugin)
  .use(Antd)
  .use(VueMonacoEditorPlugin, {
    paths: {
      // The recommended CDN config
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
    },
  })
  .mount('#app');
