<template>
  <div class="container">
    <a-divider>Config</a-divider>
    <div class="flex justify-between items-center mb-4" ref="refLoginRequired">
      <a-typography-title :level="5">Is a login required?</a-typography-title>
      <div>
        <a-radio-group v-model:value="loginEnabled" option-type="button" :options="[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]" @change="loginFormSubmit" />
      </div>
    </div>

    <a-divider>Cache</a-divider>

    <div class="flex justify-between items-center mb-4">
      <a-typography-title :level="5">Cache strategy</a-typography-title>
      <div>
        <a-radio-group v-model:value="cacheStrategy" option-type="button" :options="[
          { label: 'Permanent', value: 'permanent' },
          { label: 'Per Session', value: 'session' },
          { label: 'TTL', value: 'ttl' },
          { label: 'None', value: 'none' },
        ]" @change="updateCacheStrategy" />
      </div>
    </div>

    <div v-if="cacheStrategy === 'ttl'" class="flex justify-between items-center">
      <a-typography-title :level="5">TTL Time</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-input class="!w-72" v-model:value="ttlTime" placeholder="TTL in ms" />
          <a-button @click="ttlTimeSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>

    <a-divider>Callbacks</a-divider>

    <div class="flex justify-between items-center mb-4">
      <a-typography-title :level="5">On Mount</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-input class="!w-72" v-model:value="onMountUrl" placeholder="https:my-domain/api/mount" />
          <a-button @click="onMountUrlSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>

    <a-divider>Store</a-divider>
    <div class="flex justify-between items-center mb-4">
      <a-typography-title :level="5">Publish to store</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-button type="primary" @click="showPublishToStoreModal">Publish</a-button>
          <a-modal :open="publishToStoreModalOpen" title="Publish Screen" @ok="publishToStoreModalHandleOk"
            @cancel="publishToStoreModalHandleCancel" width="500px">

            <!-- Explanation text for the user -->
            <div class="mb-4">
              <p class="text-gray-700">To publish this screen to the store, please provide a title and description for
                the screen. These will be used to represent your screen in the store.</p>
            </div>

            <!-- Input fields for the user to provide title and description -->
            <a-form :layout="'vertical'" name="basic" :model="publishScreenFormState">
              <!-- Title Input Field -->

              <a-form-item label="Title" :rules="[{ required: true }]"
                :validate-status="publishScreenFormStateErrors.title ? 'error' : ''"
                :help="publishScreenFormStateErrors.title ? 'This field is required' : ''">
                <a-input name="title" v-model:value="publishScreenFormState.title" class="!w-72"
                  placeholder="Enter the title of the screen" />
              </a-form-item>

              <!-- Description Input Field -->
              <a-form-item label="Description" :rules="[{ required: true }]"
                :validate-status="publishScreenFormStateErrors.description ? 'error' : ''"
                :help="publishScreenFormStateErrors.description ? 'This field is required' : ''">
                <a-input name="description" v-model:value="publishScreenFormState.description" class="!w-72"
                  placeholder="Enter a short description of the screen" />
              </a-form-item>

            </a-form>

            <!-- Extra Notes (Optional) -->
            <div class="mt-4">
              <p class="text-sm text-gray-500">Please ensure the title is concise and the description accurately
                reflects the content of the screen.</p>
            </div>

          </a-modal>
        </a-input-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useUpdateScreen } from '@/application/updateScreen';
import { useProjectStorage } from '@/services/storageAdapter';
import { useSetScreenLoginRequirement } from '@/application/setScreenLoginRequirement';
import { usePublishScreenToStore } from '@/application/publishScreenToStore';

const route = useRoute();
const { loadAllProjects, loadScreensByProjectID, loadConfigsByProjectID, getById } = useProjectStorage();
const { require } = useSetScreenLoginRequirement();
const { update } = useUpdateScreen();
const { publish } = usePublishScreenToStore();

const project_id = computed(() => route.params.project_id);
const screen_id = computed(() => route.params.screen_id);

const project = getById(project_id.value);
const screen = computed(() => project.value?.screens?.find(x => String(x.id) === String(screen_id.value)) || null);

const cacheStrategy = ref('none');
const ttlTime = ref(null);
const onMountUrl = ref(null);
const publishToStoreModalOpen = ref(false);
const loginEnabled = ref(false);

const publishScreenFormState = reactive({
  title: null,
  description: null,
});

const publishScreenFormStateErrors = reactive({
  title: false,
  description: false,
});

// Watch screen and update refs accordingly
watch(screen, (newScreen) => {
  if (!newScreen?.config) return;
  cacheStrategy.value = newScreen.config.cache_strategy || 'none';
  ttlTime.value = newScreen.config.ttl_time || null;
  onMountUrl.value = newScreen.config.on_mount_url || null;
}, { immediate: true });

watch([screen, project], ([newScreen, newProject]) => {
  if (!newProject || !newScreen) return;

  newProject.configs?.forEach((element) => {
    if (element.name == 'login') return loginEnabled.value = element.value.enabled.includes(newScreen.name);
  });
}, { immediate: true, deep: true });

const onMountUrlSubmit = async () => {
  await update(
    screen.value.project_id,
    screen_id.value,
    screen.value.name,
    { config: { ...screen.value.config, on_mount_url: onMountUrl.value } }
  );
}

const ttlTimeSubmit = async () => {
  await update(
    screen.value.project_id,
    screen_id.value,
    screen.value.name,
    { config: { ...screen.value.config, ttl_time: ttlTime.value } }
  );
}

const updateCacheStrategy = async () => {
  await update(
    screen.value.project_id,
    screen_id.value,
    screen.value.name,
    { config: { ...screen.value.config, cache_strategy: cacheStrategy.value, ttl_time: ttlTime.value } }
  );
}

const showPublishToStoreModal = (_) => {
  publishToStoreModalOpen.value = true;
}

const publishToStoreModalHandleOk = async (_) => {
  if (!publishScreenFormState.title || !publishScreenFormState.description) {
    publishScreenFormStateErrors.title = publishScreenFormState.title ? false : true;
    publishScreenFormStateErrors.description = publishScreenFormState.description ? false : true;
    return;
  }

  await publish(screen_id.value, publishScreenFormState.title, publishScreenFormState.description);

  publishToStoreModalOpen.value = false;
  publishScreenFormState.value = {
    title: '',
    description: ''
  }
};

const publishToStoreModalHandleCancel = (_) => {
  publishToStoreModalOpen.value = false;
};

const loginFormSubmit = async () => {
  await require(screen.value.project_id, screen.value.name, loginEnabled.value);
};

onMounted(async () => {
  await loadAllProjects();
  await loadScreensByProjectID(project_id.value);
  await loadConfigsByProjectID(project_id.value);
});
</script>