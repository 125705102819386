<template>
  <div class="flex justify-center items-center min-h-screen bg-gray-900 text-white">
    <form
      class="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full"
      @submit.prevent="handleRegister"
    >
      <h1 id="forgot-password-title" class="text-2xl font-bold mb-4 text-center">
        Forgot Password
      </h1>
      <p class="text-sm text-gray-400 text-center mb-6">
        Enter your email address below to receive password reset instructions.
      </p>

      <!-- Error Message -->
      <div v-if="errorMessage" class="mb-4 text-red-500 text-center">
        {{ errorMessage }}
      </div>

      <!-- Success Message -->
      <div v-if="message" class="mb-4 text-green-500 text-center">
        {{ message }}
      </div>

      <!-- Input Field -->
      <div class="mb-4">
        <input
          type="email"
          id="email"
          v-model="email"
          class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-200"
          placeholder="you@example.com"
          required
        />
      </div>

      <!-- Submit Button with Countdown -->
      <button
        type="submit"
        :disabled="loading || countdown > 0"
        class="w-full py-2 px-4 rounded-md font-semibold transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2"
        :class="{
          'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500': countdown === 0,
          'bg-gray-600 text-gray-400 cursor-not-allowed': countdown > 0
        }"
      >
        <span v-if="loading">Sending...</span>
        <span v-else-if="countdown > 0">Resend in {{ countdown }}s</span>
        <span v-else>Send</span>
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { AxiosError } from "axios";
import { useAuthenticate } from "@/application/authenticate";

const email = ref<string>("");
const loading = ref<boolean>(false);
const message = ref<string>("");
const errorMessage = ref<string>("");
const countdown = ref<number>(0);
let timer: ReturnType<typeof setInterval> | null = null;
const { sendForgotPasswordEmail } = useAuthenticate();

const handleRegister = async () => {
  if (!email.value) {
    errorMessage.value = "Email is required.";
    return;
  }

  loading.value = true;
  errorMessage.value = "";
  message.value = "";

  try {
    await sendForgotPasswordEmail(email.value);
    message.value = "Reset password instructions sent to your email.";
    startCountdown();
  } catch (err: unknown) {
    const error = err as AxiosError;
    if (error.response?.data?.message === ":user_not_found") {
      errorMessage.value = "User not found";
    } else {
      errorMessage.value = "An error occurred. Please try again.";
    }
  } finally {
    loading.value = false;
  }
};

const startCountdown = () => {
  countdown.value = 60;
  timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      if (timer) {
        clearInterval(timer);
      }
    }
  }, 1000);
};
</script>
