import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import { ProjectService, ProjectStorageService, UserStorageService } from "./ports";

export function useInstallScreenFromStore() {
    const userStorage: UserStorageService = useUserStorage();
    const projectStorage: ProjectStorageService = useProjectStorage();
    const projectService: ProjectService = useProject()

    async function install(screenId: UniqueId, projectId: UniqueId): Promise<void> {
        const userId = userStorage.user?.value.id;

        if (!userId) throw Error('User not found');

        await projectService.installScreenFromStore(screenId, projectId);
        await projectStorage.loadScreensByProjectID(projectId, true);
    }

    return {
        install,
    };
}