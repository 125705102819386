<template>
  <div class="constructor">
    <!-- Main Container -->
    <!-- Component Search and List -->
    <section v-if="useNewComponentList" class="component-section bg-white">
      <a-input-search v-model:value="searchComponentName" placeholder="input name" />
      <div class="component-list scrollable mb-3">
        <ComponentListOld :list="widgets" />
      </div>
    </section>
    <section v-else class="component-section">
      <div class="component-list scrollable mb-3">
        <ComponentList :list="widgets" />
      </div>
    </section>

    <!-- Phone Image with Nested Constructor -->
    <section class="phone-container">
      <img :src="require('@/assets/phone_1.png')" alt="Phone" class="phone-image" />
      <div class="nested-constructor scrollable">
        <div v-if="loadingAIAnswer" class="flex justify-center items-center min-h-screen">
          <a-spin size="large" />
        </div>
        <NestedConstructor class="dropzone" v-model:list="inDropZoneComponents" @focus="handleComponentFocus"
          @change="hangeInDropZoneComponentsChange" />
      </div>
    </section>

    <!-- Configuration Menu -->
    <section :class="{ 'constructor-config-menu relative': true }">
      <div class="flex bg-[#1E1E1E]">
        <div class="flex items-center mr-1 group">
          <a-button class="flex items-center text-white hover:!text-blue-500" type="text" size="small"
            @click="navigateBack()">
            <ArrowLeftOutlined />
            <span class="ml-2 text-sm">Back</span>
          </a-button>
        </div>
        <a-menu mode="horizontal" class="menu-bar">
          <!-- File Menu -->
          <a-sub-menu key="file" title="File">
            <a-menu-item key="save" @click="handleSave(false)">
              <SaveOutlined /> Save
            </a-menu-item>
            <a-menu-item key="saveBroadcast" @click="handleSave(true)">
              <ShareAltOutlined /> Save & Broadcast
            </a-menu-item>
            <a-menu-item key="import" @click="triggerFileInput">
              <ImportOutlined /> Import
            </a-menu-item>
            <a-menu-item key="export" @click="exportToFile">
              <ExportOutlined /> Export
            </a-menu-item>
          </a-sub-menu>

          <!-- View Menu -->
          <a-sub-menu key="navigation" title="View">
            <a-menu-item key="fullscreen" @click="toggleFullscreen">
              <ExpandOutlined /> Fullscreen
            </a-menu-item>
            <a-menu-item key="fullscreen" @click="toggleAIInput">
              <RobotOutlined /> AI
            </a-menu-item>
            <a-menu-item key="fullscreen" @click="toggleUseNewComponentList">
              <RollbackOutlined /> Use old right panel
            </a-menu-item>
          </a-sub-menu>

          <!-- Help Menu -->
          <a-menu-item key="help">
            <router-link :to="{ name: 'BeActionsDoc' }" target="_blank" class="doc-link">
              Doc
            </router-link>
          </a-menu-item>

          <!-- Settings Menu -->
          <a-sub-menu key="settings" title="Settings">
            <a-menu-item class="flex justify-between" key="autoSaveBroadcast">
              <span class="mr-2">Dev Mode</span>
              <a-switch v-model:checked="isDevMode" />
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <ConstructorConfigMenu v-model:list="inDropZoneComponents" :focusedWidget="focusedComponent" />

      <!-- AI Input at the bottom of the page -->
      <div v-if="showAIInput" class="absolute bottom-2 left-0 w-full">
        <div class="flex items-center">
          <textarea :disabled="loadingAIAnswer" type="text" v-model="aiRequest" :rows="aiTextAreaRows"
            placeholder="Enter your AI request..."
            class="w-full p-1 pr-5 pl-5 rounded-md bg-[#1E1E1E] text-white focus:outline-none focus:ring-0 hover:ring-0"
            @keydown="handleKeydown"></textarea>
        </div>
      </div>

    </section>

    <!-- File Input -->
    <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" accept=".json" />
  </div>
</template>


<script setup>
import ComponentList from './helpers/ComponentList.vue';
import ComponentListOld from './helpers/ComponentListOld.vue';
import NestedConstructor from './helpers/NestedConstructor.vue';
import ConstructorConfigMenu from './helpers/ConstructorConfigMenu.vue';
import {
  ExportOutlined,
  ImportOutlined,
  SaveOutlined,
  ShareAltOutlined,
  ExpandOutlined,
  ArrowLeftOutlined,
  RobotOutlined,
  RollbackOutlined,
} from '@ant-design/icons-vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import router from '@/infra/router';
import { usePersistUnsavedScreen } from '@/application/persistUnsavedScreen';
import { useProjectStorage, useWidgetLibraryStorage } from '@/services/storageAdapter';
import { computed, onMounted, ref, watch } from 'vue';
import { useUpdateScreen } from '@/application/updateScreen';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useGenerateScreenByPrompt } from '@/application/generateScreenByPrompt';
const MAX_ROWS = 4;

const route = useRoute();
const { loadAllProjects, loadScreensByProjectID, getById } = useProjectStorage();
const { update } = useUpdateScreen();
const { widgets } = useWidgetLibraryStorage();
const { generate } = useGenerateScreenByPrompt();
const { persist } = usePersistUnsavedScreen();

const toast = useToast();
const aiRequest = ref('');
const aiTextAreaRows = ref(1);
const fileInput = ref(null);
const showAIInput = ref(false);
const useNewComponentList = ref(false);
const isDevMode = ref(false);
const loadingAIAnswer = ref(false);
const inDropZoneComponents = ref([]);
const focusedComponent = ref(null);
const project_id = computed(() => route.params.project_id);
const screen_id = computed(() => route.params.screen_id);
const project = getById(project_id.value);
const screen = computed(() => project.value?.screens?.find(x => String(x.id) === String(screen_id.value)) || null);

const handleSave = async (broadcast = false) => {
  try {
    const body = {
      nestedComponents: inDropZoneComponents.value,
    };

    await update(project_id.value, screen_id.value, screen.name, body, broadcast);
  } catch (error) {
    console.error(error)
    toast.error('Failed to update screen');
  }
}

const triggerFileInput = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
};

const exportToFile = () => {
  const jsonData = JSON.stringify(inDropZoneComponents, null, 2);
  // Creating a downloadable JSON file
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${screen.value.name}.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const navigateBack = () => {
  router.go(-1);
};

const toggleFullscreen = () => {
  if (!document.fullscreenElement && !document.webkitFullscreenElement) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};

const toggleAIInput = () => {
  showAIInput.value = !showAIInput.value;
};

const toggleUseNewComponentList = () => {
  useNewComponentList.value = !useNewComponentList.value;
};

const handleComponentFocus = (component) => {
  focusedComponent.value = component;
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        inDropZoneComponents.value = jsonData._value;
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
    reader.readAsText(file);
  }
};

const handleKeydown = (event) => {
  if (event.shiftKey && event.key === 'Enter') {
    event.preventDefault();
    aiTextAreaRows.value = Math.min(aiTextAreaRows.value + 1, MAX_ROWS);
    aiRequest.value += '\n';
  } else if (event.key === 'Enter') {
    event.preventDefault();
    submitAIRequest();
  }
};

const submitAIRequest = async () => {
  loadingAIAnswer.value = true;

  try {
    const component = await generate(aiRequest.value);
    inDropZoneComponents.value.push(component);
    handleComponentFocus(component);
    aiTextAreaRows.value = 1;
    aiRequest.value = '';
  } catch (error) {
    toast.error('Failed to process request');
  } finally {
    loadingAIAnswer.value = false;
  }
}

const hangeInDropZoneComponentsChange = () => {
  persist(screen_id.value, inDropZoneComponents.value);
  if (isDevMode.value) handleSave(true);
}

onMounted(async () => {
  await loadAllProjects();
  await loadScreensByProjectID(project_id.value);
});

watch(screen, (newValue) => {
  inDropZoneComponents.value = newValue?.nestedComponents ?? [];
}, { immediate: true });
</script>

<style scoped>
.constructor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  max-height: 100vh;
  overflow: hidden;
  background-color: #1E1E1E;
}

.component-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-list {
  width: 270px;
  max-height: 100vh;
  overflow-y: auto;
}

.phone-container {
  position: relative;
  width: 400px;
  /* Adjust based on your phone image size */
  height: 800px;
  /* Adjust based on your phone image size */
}

.phone-image {
  width: 100%;
  height: 100vh;
  max-height: 800px;
}

.nested-constructor {
  position: absolute;
  top: 7%;
  left: 10%;
  width: 80%;
  height: 80%;
  overflow-y: auto;
}

.constructor-config-menu {
  width: 700px;
}

.constructor-config-menu.expanded {
  width: 800px;
}

.mr-5px {
  margin-right: 5px;
}

.menu-bar {
  background-color: #1E1E1E !important;
  color: white;
  border-bottom: 1px solid #444;
  padding: 5px 20px;
}


.menu-bar :deep(.ant-menu-item) {
  color: white !important;
}

.menu-bar :deep(.ant-menu-submenu-title) {
  color: white !important;
}

.menu-bar :deep(.ant-menu-item:hover),
.menu-bar :deep(.ant-menu-submenu-title:hover) {
  color: #007aff !important;
}

.constructor-config-menu {
  width: 700px;
  background-color: white;
  border-radius: 10px;
}

.constructor-config-menu.expanded {
  width: 800px;
}

.doc-link {
  color: #007aff;
  text-decoration: none;
}

.doc-link:hover {
  text-decoration: underline;
}
</style>