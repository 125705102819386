import { UserName } from "./UserTypes";

export default class User {
  id: UniqueId;
  name: UserName | null;
  email: Email;
  email_verified: boolean;
  avatar: string | null;

  constructor(id: UniqueId, name: UserName | null, email: Email, emailVerified: boolean, avatar: string | null) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.email_verified = emailVerified;
    this.avatar = avatar;
  }
}
