<template>
  <router-view v-if="user" />
</template>

<script setup>
import { useAuthenticate } from '@/application/authenticate';
import { onMounted } from 'vue';

defineOptions({
  name: 'ConstructorLayout'
});

const { user, authenticateBySession } = useAuthenticate();

onMounted(async () => {
  if (!user.value) authenticateBySession();
})
</script>