import { useProject } from "@/services/projectAdapter";
import { useUserStorage } from "../services/storageAdapter";
import { ProjectService, UserStorageService } from "./ports";

export function usePublishScreenToStore() {
    const userStorage: UserStorageService = useUserStorage();
    const projectService: ProjectService = useProject()

    async function publish(screenId: UniqueId, title: string, description: string): Promise<void> {
        const userId = userStorage.user?.value.id;

        if (!userId) throw Error('User not found');

        await projectService.publishScreenToStore(screenId, title, description);
    }

    return {
        publish,
    };
}