<template>
  <div class="mb-2 flex justify-end items-center"><span class="mr-2">Enable Auto Update </span><a-switch
      v-model:checked="autoUpdateChecked" /></div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: 200 }" :expand-column-width="100">
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'timestamp'">
        {{ time2TimeAgo(record.metadata.date) }}
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <pre>
        <vue-json-pretty :data="record.payload" />
      </pre>
    </template>
    <template #expandColumnTitle>
      <span style="color: red">More</span>
    </template>
  </a-table>
</template>
<script setup>
import { time2TimeAgo } from '@/utils/time';
import { onUnmounted, ref, watch } from 'vue';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { uuid } from 'vue-uuid';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import eventAPI from '@/infra/api/events';
import { useEvent } from '@/services/eventAdapter';

const route = useRoute();

const columns = [
  { title: 'Action', key: 'action', dataIndex: 'action', fixed: true },
  { title: 'Screen ID', key: 'screen_id', dataIndex: 'screen_id', },
  { title: 'Project ID', key: 'project_id', dataIndex: 'project_id' },
  { title: 'Timestamp', key: 'timestamp' },
];

const data = ref([]);
const autoUpdateChecked = ref(false);
const activeUsersIntervalID = ref(null);
const { getActiveUserEventLog } = useEvent();

onMounted(async () => {
  data.value = await getActiveUserEventLog(route.params.user_id);
  data.value.forEach(x => x.key = uuid.v4())
});
onUnmounted(() => {
  clearInterval(activeUsersIntervalID.value);
});

watch(autoUpdateChecked, (newValuw) => {
  if (newValuw) {
    activeUsersIntervalID.value = setInterval(async () => {
      data.value = await getActiveUserEventLog(route.params.user_id);
      data.value.forEach(x => x.key = uuid.v4())
    }, 5000);
  } else {
    clearInterval(activeUsersIntervalID.value);
  }
}, { immediate: true });
</script>