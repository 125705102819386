import { useProject } from "@/services/projectAdapter";
import { useUserStorage } from "../services/storageAdapter";
import { ProjectService, UserStorageService } from "./ports";
import { ScreenComponent } from "@/domain/project/ProjectTypes";

export function useGenerateScreenByPrompt() {
    const userStorage: UserStorageService = useUserStorage();
    const projectService: ProjectService = useProject();

    async function generate(prompt: string): Promise<ScreenComponent> {
        const userId = userStorage.user?.value.id;

        if (!userId) throw Error('User not found');

        return await projectService.generateWidgetWithAI(prompt);
    }

    return {
        generate,
    };
}