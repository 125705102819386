import { uuid } from "vue-uuid";
import MiniSearch from "minisearch";

const actions = {
  click: {
    type: 'route_to_local',
    screen_name: 'home',
  },
  long_press: {
    type: 'async_post',
    url: 'url',
    params: {
      parameter: 'parameter',
    },
  },
};

const widgets = [
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'FooterWidget',
    data: {
      style: {
        'background-color': 'white',
      },
      images: [
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
      ],
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'HeaderWidget',
    data: {
      title: 'HeaderWidget',
      images: [
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
        {
          src: "https://img.icons8.com/ios/100/camera--v4.png",
          alt: '',
          actions: actions,
        },
      ],
      style: {
        background: 'white',
      },
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'SearchWidget',
    data: {
      placeholder: 'SearchWidget',
      image: "https://img.icons8.com/ios/100/camera--v4.png",
      style: {
        background: 'white',
      },
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'TextWidget',
    data: {
      text: 'TextWidget',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'ImageWidget',
    data: {
      src: 'https://cdn4.riastatic.com/photosnew/auto/photo/chevrolet_equinox__418276504f.jpg',
      alt: '',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'ItemCard1',
    data: {
      src: 'https://cdn4.riastatic.com/photosnew/auto/photo/chevrolet_equinox__418276504f.jpg',
      alt: 'alt',
      title: 'Chevrolet Equinox LTZ 2016',
      price: { uah: 10, usd: 20 },
      characteristics: [
        {
          src: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.5' cy='7.5' r='7' stroke='%23DB5C4C'/%3E%3Ccircle cx='7.5' cy='7.5' r='1' stroke='%23DB5C4C'/%3E%3Cpath d='m8.5 6.5 2-2' stroke='%23DB5C4C'/%3E%3Cpath d='m7.5 0.5v1.5m-7 5.5h1.5m12.5 0h-1.5m-10.5-5 1 1m9-1-1 1' stroke='%23DB5C4C'/%3E%3C/svg%3E%0A",
          alt: '',
          text: '2000',
        },
        {
          src: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.5' cy='7.5' r='7' stroke='%23DB5C4C'/%3E%3Ccircle cx='7.5' cy='7.5' r='1' stroke='%23DB5C4C'/%3E%3Cpath d='m8.5 6.5 2-2' stroke='%23DB5C4C'/%3E%3Cpath d='m7.5 0.5v1.5m-7 5.5h1.5m12.5 0h-1.5m-10.5-5 1 1m9-1-1 1' stroke='%23DB5C4C'/%3E%3C/svg%3E%0A",
          alt: '',
          text: 'Vinnitsya',
        },
        {
          src: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.5' cy='7.5' r='7' stroke='%23DB5C4C'/%3E%3Ccircle cx='7.5' cy='7.5' r='1' stroke='%23DB5C4C'/%3E%3Cpath d='m8.5 6.5 2-2' stroke='%23DB5C4C'/%3E%3Cpath d='m7.5 0.5v1.5m-7 5.5h1.5m12.5 0h-1.5m-10.5-5 1 1m9-1-1 1' stroke='%23DB5C4C'/%3E%3C/svg%3E%0A",
          alt: '',
          text: '1.2',
        },
        {
          src: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='none' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.5' cy='7.5' r='7' stroke='%23DB5C4C'/%3E%3Ccircle cx='7.5' cy='7.5' r='1' stroke='%23DB5C4C'/%3E%3Cpath d='m8.5 6.5 2-2' stroke='%23DB5C4C'/%3E%3Cpath d='m7.5 0.5v1.5m-7 5.5h1.5m12.5 0h-1.5m-10.5-5 1 1m9-1-1 1' stroke='%23DB5C4C'/%3E%3C/svg%3E%0A",
          alt: '',
          text: 'auto',
        },
      ],
      labels: [{ text: 'AS1234AS' }, { text: 'ZXC029384SDFKh09LKF' }],
      date: '2021-02-02',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'ItemCard2',
    data: {
      src: 'https://cdn.riastatic.com/photosnew/dom/photo/prodaja-kvartira-vinnitsa-staryiy-gorod-okkina-lia__178521498xg.jpg',
      alt: '',
      price: { uah: 10, usd: 20 },
      title: 'Vinnitsya',
      sub_title: 'Vinnitsya 1',
      params: [{ text: 'Vinnitsya 2' }, { text: '1 room' }, { text: '1 room' }],

      labels: [
        { id: 1, text: 'text1' },
        { id: 2, text: 'text2' },
        { id: 2, text: 'text2' },
        { id: 2, text: 'text2' },
        { id: 2, text: 'text2' },
        { id: 2, text: 'text2' },
        { id: 2, text: 'text2' },
        { id: 3, text: 'text3' },
      ],
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'LineWidget',
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'Label1Widget',
    data: {
      text: 'label1',
      src: "https://img.icons8.com/ios/100/camera--v4.png",
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'Label2Widget',
    data: {
      text: 'label1',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'Label3Widget',
    data: {
      text: 'label1',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'ChatPreviewWidget',
    data: {
      title: 'Title',
      text: 'Lorem ipsum dolor sit amet, consectetur',
      src: 'https://cdn4.riastatic.com/photosnew/auto/photo/chevrolet_equinox__418276504f.jpg',
      date: '2021.01.01',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'ChatMessageWidget',
    data: {
      memo: '',
      name: 'Dmytro',
      text: 'Lorem ipsum dolor sit amet, consectetur',
      date: '2021.01.01',
      cells: 3,
      images: [
        'https://images.unsplash.com/photo-1606814893907-c2e42943c91f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lybHxlbnwwfHwwfHx8MA%3D%3Dhttps://images.unsplash.com/photo-1606814893907-c2e42943c91f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lybHxlbnwwfHwwfHx8MA%3D%3D',
        'https://plus.unsplash.com/premium_photo-1668319915384-3cccf7689bef?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Z2lybHxlbnwwfHwwfHx8MA%3D%3D',
        'https://images.unsplash.com/photo-1631947430066-48c30d57b943?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Z2lybHxlbnwwfHwwfHx8MA%3D%3D',
        'https://plus.unsplash.com/premium_photo-1664366737698-3a98169201c3?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGdpcmx8ZW58MHx8MHx8fDA%3D',
      ],
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'ImageCarouselWidget',
    data: {
      images: [
        {
          id: 1,
          src: 'https://plus.unsplash.com/premium_photo-1673292293042-cafd9c8a3ab3?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bmF0dXJlfGVufDB8fDB8fHww',
        },
        {
          id: 2,
          src: 'https://images.unsplash.com/photo-1472396961693-142e6e269027?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmF0dXJlfGVufDB8fDB8fHww',
        },
        {
          id: 3,
          src: 'https://images.unsplash.com/photo-1509316975850-ff9c5deb0cd9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bmF0dXJlfGVufDB8fDB8fHww',
        },
        {
          id: 4,
          src: 'https://images.unsplash.com/photo-1644270580946-74cf7fd99f97?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHRiaWxpc2l8ZW58MHx8MHx8fDA%3D',
        },
      ],
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'IconWidget',
    data: {
      src: 'https://cdn4.riastatic.com/photosnew/auto/photo/chevrolet_equinox__418276504f.jpg',
      alt: '',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'InputWidget',
    data: {
      type: 'text',
      placeholder: 'simple text',
      memo: '',
      name: 'text',
      disabled: false,
      required: false,
      value: 'default value',
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'TextAreaWidget',
    data: {
      memo: '',
      name: 'name',
      placeholder: 'name',
      rows: '2',
      cols: '3',
      disabled: false,
      required: false,
      value: 'default value',
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'SelectWidget',
    data: {
      memo: '',
      name: 'name',
      disabled: false,
      required: false,
      selected: '3',
      options: [
        { id: 1, value: '1', text: 'select1' },
        { id: 2, value: '2', text: 'select2' },
        { id: 3, value: '3', text: 'select3' },
        { id: 4, value: '4', text: 'select4' },
        { id: 5, value: '5', text: 'select5' },
      ],
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'ButtonWidget',
    data: {
      type: 'submit',
      disabled: false,
      memo: '',
      name: 'name',
      value: 'value',
      text: 'button',
    },
  },
  {
    id: 24,
    memo: '',
    type: 'composite',
    name: 'FormWidget',
    nestedComponents: [
      {
        id: 20,
        memo: '',
        name: 'InputWidget',
        data: {
          form_id: 24,
          type: 'text',
          placeholder: 'simple text',
          memo: '',
          name: 'input',
          disabled: false,
          required: false,
          value: 'default value',
        },
      },
      {
        id: 21,
        memo: '',
        name: 'TextAreaWidget',
        data: {
          form_id: 24,
          memo: '',
          name: 'textarea',
          placeholder: 'name',
          rows: '2',
          cols: '3',
          disabled: false,
          required: false,
          value: 'default value',
        },
      },
      {
        id: 22,
        memo: '',
        name: 'SelectWidget',
        data: {
          form_id: 24,
          memo: '',
          name: 'select',
          disabled: false,
          required: false,
          options: [
            { id: 1, value: '1', text: 'select1' },
            { id: 2, value: '2', text: 'select2' },
            { id: 3, value: '3', text: 'select3' },
            { id: 4, value: '4', text: 'select4', selected: true },
            { id: 5, value: '5', text: 'select5' },
          ],
        },
      },
      {
        id: 23,
        memo: '',
        name: 'ButtonWidget',
        data: {
          form_id: 24,
          type: 'submit',
          disabled: false,
          memo: '',
          name: 'name',
          value: 'value',
          text: 'button',
        },
      }
    ],
    data: {
      action: '/action',
      method: 'post',
    },
  },
  // {
  //   id: 30,
  // name: "PaginationWidget",
  //   data: {
  //     callbackUrl: "https://cdn4.riastatic.com/photosnew/auto/photo/chevrolet_equinox__418276504f.jpg",
  //   }
  // },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'GoogleMapWidget',
    data: {
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
          memo: '',
          name: 'id=1',
          price: '100',
          styleType: 'price',
          onClick: {
            type: 'async_post',
            callbackUrl:
              'https://icon-library.com/images/img-icon/img-icon-14.jpg',
          },
        },
        {
          position: {
            lat: 51.083048,
            lng: 6.24212,
          },
          memo: '',
          name: 'id=2',
          price: '200',
          onClick: {
            type: 'async_post',
            callbackUrl:
              'https://icon-library.com/images/img-icon/img-icon-14.jpg',
          },
        },
      ],
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'MaplibreWidget',
    data: {
      actions: {
        track_position: {
          type: 'async_post',
          url: 'url',
        },
      },
      style: {
        width: '100%',
        height: '600px',
      },
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
          memo: '',
          name: 'id=1',
          type: 'text',
          text: 'text',
          click: {
            type: 'async_post',
            callbackUrl:
              'https://icon-library.com/images/img-icon/img-icon-14.jpg',
          },
        },
        {
          position: {
            lat: 51.083048,
            lng: 6.24212,
          },
          memo: '',
          name: 'id=2',
          type: 'text',
          text: 'text',
          click: {
            type: 'async_post',
            callbackUrl:
              'https://icon-library.com/images/img-icon/img-icon-14.jpg',
          },
        },
      ],
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    type: 'composite',
    name: 'Item2Header',
    data: {
      price: { uah: 10, usd: 20 },
      title: 'Vinnitsya',
      sub_title: 'Vinnitsya 1',
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'ListWidget',
    data: {
      list: ['item1', 'item2', 'item3'],
      actions: actions,
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'DateTimePickerWidget',
    data: {
      src: 'https://img.icons8.com/clouds/100/calendar--v2.png',
      text: 'Select Date',
      mode: 'date', //time
      actions: {
        change: {
          type: 'async_post',
          url: 'url',
          params: {
            parameter: 'parameter',
          },
        },
      },
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'ApiWidget',
    data: {
      callbackUrl: 'http://localhost:4000/api/v1/test',
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'PaginationWidget',
    data: {
      callbackUrl: 'http://localhost:4000/',
    },
  },
  {
    id: 'a4cced1f-1dbb-4f05-ab9d-b80a61578e9f',
    memo: '',
    name: 'WebViewWidget',
    data: {
      url: 'https://google.com',
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'PopupWidget',
    nestedComponents: [],
    data: {
      message: 'aaaa',
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'SimpleRow',
    type: 'layout',
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'SimpleWrap',
    type: 'layout',
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'TwoColumn',
    type: 'layout',
    data: {
      columns: 4,
    },
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'FixedTop',
    type: 'layout',
    isAbsolute: true,
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'Drawer',
    type: 'layout',
    drawer_position: 'left',
    drawer_width: 300,
    drawer_lock_mode: 'unlocked',
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'FixedBottom',
    type: 'layout',
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'FixedCenter',
    type: 'layout',
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'InputWithButtonWidget',
    data: {
      placeholder: 'placeholder',
      icon_src: '',
      actions: {
        change: {
          type: 'async_post',
          url: 'url',
          params: {
            parameter: 'parameter',
          },
        },
        submit: {
          type: 'async_post',
          url: 'url',
          params: {
            parameter: 'parameter',
          },
        },
      },
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'CustomWidget',
    data: {
      widget_id: '',
      params1: 'params',
    },
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'TinderWidget',
    data: {
      cards: [
        {
          src: '',
          title: '',
          description: '',
        },
        {
          src: '',
          title: '',
          description: '',
        },
      ],
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'ImageLibraryWidget',
    data: {
      columns: 4,
    },
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'CameraWidget',
    data: {
      columns: 4,
    },
    nestedComponents: [],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'SwipeableLayout',
    data: {
      rightActions: [
        {
          id: uuid.v4(),
          src: 'https://cdn3.iconfinder.com/data/icons/lined-electro-car/48/a-11-256.png',
          color: 'red',
          actions: actions,
        },
        {
          id: uuid.v4(),
          src: 'https://cdn3.iconfinder.com/data/icons/lined-electro-car/48/a-11-256.png',
          color: 'green',
          actions: actions,
        },
      ],
      leftActions: [
        {
          id: uuid.v4(),
          src: 'https://cdn3.iconfinder.com/data/icons/lined-electro-car/48/a-11-256.png',
          color: 'green',
          actions: actions,
        },
      ],
    },
    nestedComponents: [
      {
        id: uuid.v4(),
        memo: '',
        name: 'ChatPreviewWidget',
        data: {
          title: 'Title',
          text: 'Lorem ipsum dolor sit amet, consectetur',
          src: 'https://cdn4.riastatic.com/photosnew/auto/photo/chevrolet_equinox__418276504f.jpg',
          date: '2021.01.01',
          actions: actions,
        },
      },
    ],
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'ShareWidget',
    data: {
      message: 'this is a text to share',
      url: 'this is a link to share',
      actions: {
        share: {
          type: 'async_post',
          url: 'url',
          params: {
            parameter: 'parameter',
          },
        },
      },
    },
    nestedComponents: [],
  },
];

const mangusList = [
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusButtonWidget',
    display_name: 'Button',
    nestedComponents: [],
    data: {
      text: {
        value: "Contact Us",
      },
      props: {
        mt: "lg",
        px: "xl",
        py: "lg",
        bg: "white",
        borderWidth: 1,
        borderColor: "red500",
        color: "red500",
        underlayColor: "red100",
      }
    },
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusImageWidget',
    display_name: 'Image',
    data: {
      props: {
        h: 100,
        w: 100,
        m: 10,
        rounded: "circle",
        source: { uri: "https://images.unsplash.com/photo-1593642532400-2682810df593?ixid=MXwxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60" }
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusIconWidget',
    display_name: 'Icon',
    data: {
      props: {
        bg: "yellow400",
        p: 20,
        rounded: "circle",
        name: "star",
        color: "yellow700",
        fontSize: "xl",
        fontFamily: "FontAwesome",
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusTextWidget',
    display_name: 'Text',
    data: {
      text: "MangusTextWidget",
      props: {
        fontSize: "lg",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: "red400",
        letterSpacing: 2,
        mt: "lg",
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusDivWidget',
    display_name: 'Div',
    nestedComponents: [],
    data: {
      props: {
        h: 40,
        w: 40,
        bg: "red200",
        borderColor: "red500",
        borderWidth: 1
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusFabWidget',
    display_name: 'Fab',
    nestedComponents: [],
    data: {
      props: {
        bg: "blue600",
        h: 50,
        w: 50
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusBadgeWidget',
    display_name: 'Badge',
    nestedComponents: [],
    data: {
      text: {
        value: "9+"
      },
      props: {
        ml: "md",
        bg: "red500"
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusAvatarWidget',
    display_name: 'Avatar',
    data: {
      image: {
        props: {
          name: "user",
          color: "white",
          fontFamily: "Feather"
        }
      },
      props: {
        bg: "green800",
        rounded: "lg"
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusHeaderWidget',
    display_name: 'Header',
    nestedComponents: [],
    data: {
      prefix: {
        props: {
          name: "arrow-left",
          fontFamily: "Feather",
          fontSize: "2xl"
        }
      },
      sufix: {
        props: {
          name: "more-vertical",
          fontFamily: "Feather",
          fontSize: "2xl"
        }
      },
      props: {
        alignment: "left",
        p: "lg",
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusCollapseWidget',
    display_name: 'Collapse',
    nestedComponents: [],
    data: {
      prefix: {
        props: {
          name: "wallet",
          mr: "md",
          color: "gray400"
        }
      },
      body: {
        props: {
          pb: "xl"
        }
      },
      header: {
        text: {
          value: "Header",
        },
        props: {
          active: true,
          color: "gray900",
          fontSize: "md",
          p: "xl",
          px: "none"
        }
      }
    }
  },
  {
    id: uuid.v4(),
    memo: '',
    name: 'MangusInputWidget',
    display_name: 'Input',
    data: {
      suffix: {
        props: {
          name: "search",
          color: "gray900",
          fontFamily: "Feather"
        }
      },
      props: {
        placeholder: "Username",
        p: 10,
        focusBorderColor: "blue700"
      }
    }
  },
];

const componentListSearch = new MiniSearch({
  fields: ['name', 'memo', 'display_name', 'data'],
  storeFields: ['id', 'name'],
  searchOptions: {
    prefix: true,
    boost: { name: 2 },
    fuzzy: 0.3
  }
});

const list = mangusList.concat(widgets as any);
componentListSearch.addAll(list);

export { componentListSearch, list };
