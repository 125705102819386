import { Component, computed } from "vue";
import { useStore } from "vuex";
import configAPI from "@/infra/api/configs";
import User from "@/domain/user/User";
import projectAPI from "@/infra/api/projects";
import Project from "@/domain/project/Project";
import { Screen, ScreenComponent} from "@/domain/project/ProjectTypes";
import { UserTokenEntity } from "@/domain/user/UserTypes";
import { CustomWidget } from "@/domain/custom_widget/CustomWidget";
import { AuthStorageService, BuildInfoStorageService, CustomWidgetStorageService, EventStorageService, ProjectStorageService, ScreenStorageService, StoreStorageService, UserStorageService, WidgetsLibraryStorageService } from "@/application/ports";
import screenAPI from "@/infra/api/screens";
import eventAPI from "@/infra/api/events";
import PublishedScreen from "@/domain/store/PublishedScreen";
import buildInfoAPI from "@/infra/api/buildInfo";
import widgetAPI from "@/infra/api/widgets";
import { list } from "@/infra/store/modules/data";

export function useUserStorage(): UserStorageService {
    const store = useStore();

    const updateUser = (user: User) => {
        store.commit('user/setUser', new User(
            user.id,
            user.name,
            user.email,
            user.email_verified,
            user.avatar
        ));
    };

    return {
        user: computed(() => store.state.user.user),
        updateUser,
    };
}

export function useAuthStorage(): AuthStorageService {
    const updateUserToken = (userToken: UserTokenEntity) => {
        localStorage.setItem('user-token', userToken.token);
    };

    const removeUserToken = () => {
        localStorage.removeItem('user-token');
    };

    return {
        token: localStorage.getItem('user-token'),
        updateUserToken,
        removeUserToken,
    };
}

export function useProjectStorage(): ProjectStorageService {
    const store = useStore();
    const projectsMap = store.getters['projects/projectsMap'];
    const { user } = useUserStorage();

    const addProjects = (projects: Project[]) => {
        projects.forEach((project: Project) => {
            const projectEntity = new Project(
                project.id,
                project.name,
                project.token,
                project.api_token,
                project.owner_id,
                project.configs,
                project.collaborators,
                project.screens,
            );
            store.commit('projects/addProject', projectEntity);
        })
    };

    const getById = (projectId: UniqueId) => {
        return computed(() => projectsMap.get(projectId));
    };

    const deleteById = (id: UniqueId) => {
        projectsMap.delete(id);
    };

    const reloadSharedProjects = async () => {
        if (user?.value?.id) {
            const sharedProjectList = await projectAPI.getAllSharedProjects(user.value.id);
            addProjects(sharedProjectList);
        }
    };

    const reloadOwnProjects = async () => {
        if (user?.value?.id) {
            const ownedProjectList = await projectAPI.getAllOwnedProjects(user.value.id);
            addProjects(ownedProjectList);
        }
    };

    const loadAllProjects = async () => {
        if (projectsMap.size == 0) {
            await reloadSharedProjects();
            await reloadOwnProjects();
        }
    };

    const loadConfigsByProjectID = async (id: UniqueId) => {
        const project = projectsMap.get(id) as Project;
        if (project && project.configs) return;

        const configs = await configAPI.getConfigs(id)
        store.commit('projects/setProjectConfigs', { projectId: id, configs: configs });
    }

    const loadCollaboratorsByProjectID = async (id: UniqueId) => {
        const project = projectsMap.get(id) as Project;
        if (project && project.collaborators) return;

        const collaborators = await projectAPI.getAllProjectCollaborators(id);
        store.commit('projects/setProjectCollaborators', { projectId: id, collaborators: collaborators });
    }

    const loadScreensByProjectID = async (id: UniqueId, force: Boolean = false) => {
        const project = projectsMap.get(id) as Project;
        if (!force && project && project.screens) return;

        const screens = await screenAPI.getAllProjectScreens(id);
        store.commit('projects/setProjectScreens', { projectId: id, screens });
    }

    const addScreens = (id: UniqueId, screens: Screen[]) => {
        const project = projectsMap.get(id) as Project;
        if (project) {
            screens.forEach(item => {
                deleteScreenById(id, item.id);
                project.screens?.push(item)
            })
            addProjects([project]);
            return;
        };
        throw Error("Project not found");
    };

    const deleteScreenById = (project_id: UniqueId, screen_id: UniqueId) => {
        const project = projectsMap.get(project_id) as Project;
        if (project) {
            const filteredScreens = project.screens?.filter(item => item.id !== screen_id);
            project.screens = filteredScreens;
            addProjects([project]);
            return;
        };
        throw Error("Project not found");
    };

    const updateProjectConfig = (id: UniqueId, config: Config) => {
        const project = projectsMap.get(id) as Project;
        if (project) {

            const updatedConfigData = project.configs?.map((item: Config) =>
                item.name === config.name ? config : item
            );

            store.commit('projects/setProjectConfigs', { projectId: id, configs: updatedConfigData });
            return;
        }
        throw Error("Project not found");
    }

    const deleteProjectCollaborator = async (id: UniqueId, inviteeId: UniqueId) => {
        const project = projectsMap.get(id) as Project;
        if (project && project.collaborators) {
            store.commit('projects/deleteProjectCollaborator', { projectId: id, inviteeId: inviteeId });
            return;
        };
        throw new Error("Project not found");
    }

    return {
        myProjects: computed(() => Array.from(projectsMap as Map<string, Project>).filter(x => x[1].owner_id === user?.value.id).map(x => x[1])),
        sharedProjects: computed(() => Array.from(projectsMap as Map<string, Project>).filter(x => x[1].owner_id !== user?.value.id).map(x => x[1])),
        loadAllProjects,
        reloadSharedProjects,
        reloadOwnProjects,
        deleteById,
        deleteScreenById,
        addProjects,
        addScreens,
        loadConfigsByProjectID,
        loadCollaboratorsByProjectID,
        loadScreensByProjectID,
        deleteProjectCollaborator,
        updateProjectConfig,
        getById
    };
}

export function useEventStorage(): EventStorageService {
    const store = useStore();

    const loadActions = async () => {
        const actions = store.getters['events/getActions']
        if (actions && actions.length) return;

        const result = await eventAPI.getActionList();
        store.commit('events/setActions', result.list);
    };

    return {
        actions: computed(() => store.getters['events/getActions']),
        loadActions,
    };
}

export function useBuildInfoStorage(): BuildInfoStorageService {
    const store = useStore();

    const loadBuildInfo = async () => {
        if(store.getters['build/buildInfo']) return;
        const currentBuildInfo = await buildInfoAPI.getBuildInfo();
        store.commit('build/setBuildInfo', currentBuildInfo);
    };

    return {
        loadBuildInfo,
        buildInfo: computed(() => store.getters['build/buildInfo']),
    };
}

export function useStoreStorage(): StoreStorageService {
    const store = useStore();
    const screensMap = store.getters['store/screensMap'];

    const loadAllPublishedScreens = async () => {
        const publishedScreens = await screenAPI.getAllPublishedScreens();
        addPublishedScreens(publishedScreens);
    };

    const addPublishedScreens = (screens: PublishedScreen[]) => {
        screens.forEach((screen: PublishedScreen) => {
            const screenEntity = new PublishedScreen(
                screen.id,
                screen.author_id,
                screen.original_screen_id,
                screen.original_screen_revision,
                screen.title,
                screen.description,
                screen.nestedComponents,
            );
            store.commit('store/addScreen', screenEntity);
        })
    };

    return {
        screens: computed(() => Array.from(screensMap as Map<string, PublishedScreen>).map(x => x[1])),
        loadAllPublishedScreens,
    };
}

export function useCustomWidgetStorage(): CustomWidgetStorageService {
    const store = useStore();
    const widgetsMap = store.getters['widgets/widgetsMap'];

    const addCustomWidgets = (widgets: CustomWidget[]) => {
        widgets.forEach((custom_widget: CustomWidget) => {
            const widgetEntity = new CustomWidget(
                custom_widget.id,
                custom_widget.name,
                custom_widget.content,
                custom_widget.updated_at,
            );
            store.commit('widgets/addWidget', widgetEntity);
        })
    };

    const loadAllCustomWidgets = async () => {
        if(widgetsMap.size > 0) return;

        const customWidgets = await widgetAPI.getAllCustomWidgets();
        addCustomWidgets(customWidgets);
    };

    return {
        loadAllCustomWidgets,
        addCustomWidgets,
        widgets: computed(() => Array.from(widgetsMap as Map<string, CustomWidget>).map(x => x[1])),
    };
}

export function useWidgetLibraryStorage(): WidgetsLibraryStorageService {
    return {
        widgets: list as ScreenComponent[],
    };
}

// fix me
export function useScreenStorage(): ScreenStorageService {
    const persistUnsavedChanges = (screenId: UniqueId, nestedComponents: Component[]) => {
        const unsavedChanges = localStorage.getItem('unsaved-screen');

        let updated = {};
        if (unsavedChanges) {
            const parsed = JSON.parse(unsavedChanges);
            updated = { ...parsed, [screenId]: nestedComponents }
        }

        localStorage.setItem('unsaved-screen', JSON.stringify(updated));
    };

    return {
        persistUnsavedChanges,
    };
}
