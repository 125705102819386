import User from "../user/User";
import {
  ProjectApiToken,
  ProjectToken,
  ProjectName,
  Screen
} from "./ProjectTypes";

export default class Project {
  id: UniqueId;
  name: ProjectName;
  token: ProjectToken;
  api_token: ProjectApiToken;
  owner_id: UniqueId;
  configs: Config[] | null | undefined;
  collaborators: User[] | null | undefined;
  screens: Screen[] | null | undefined;

  constructor(
    id: UniqueId,
    name: ProjectName,
    token: ProjectToken,
    apiToken: ProjectApiToken,
    ownerId: UniqueId,
    configs: Config[] | null | undefined,
    collaborators: User[] | null | undefined,
    screens: Screen[] | null | undefined,
  ) {
    this.id = id;
    this.name = name;
    this.token = token;
    this.api_token = apiToken;
    this.owner_id = ownerId;
    this.configs = configs;
    this.collaborators = collaborators;
    this.screens = screens;
  }
}
