<template></template>

<script setup>
import { useAuthenticate } from '@/application/authenticate';
import router from '@/infra/router';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';

const route = useRoute();
const $toast = useToast();
const {validateForgotPasswordToken} = useAuthenticate();

onMounted(async () => {
  if (!route.query.user_id || !route.query.token) {
    $toast.error('Link is invalid');
    router.replace({ name: 'Login' });
    return; // Stop execution if the link is invalid
  }

  try {
    await validateForgotPasswordToken(route.query.token, route.query.user_id);
    router.replace({ name: 'UserProfile' });
  } catch (error) {
    $toast.error('Oops.. Try again later');
    router.replace({ name: 'Login' }); // Redirect on failure
  }
});
</script>