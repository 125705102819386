import { ProjectName } from '@/domain/project/ProjectTypes';
import api from '@/utils/axios';

const base_path = '/api/admin/v1/projects';

const projectAPI = {
  async getAllOwnedProjects(userId: UniqueId) {
    const response = await api.get(`${base_path}/list/${userId}`);
    return response.data;
  },
  async getAllSharedProjects(userId: UniqueId) {
    const response = await api.get(`${base_path}/accessed_projects_list/${userId}`);
    return response.data;
  },
  async createProject(name: ProjectName) {
    const response = await api.post(base_path, { project: {name: name} });
    return response.data;
  },
  async updateProject(project_id: UniqueId, name: ProjectName) {
    const response = await api.put(`${base_path}/${project_id}`, { project: {name: name} });
    return response.data;
  },
  async deleteProject(project_id: UniqueId) {
    const response = await api.delete(`${base_path}/${project_id}`);
    return response.data;
  },
  async regenerateAPIToken(project_id: UniqueId) {
    const response = await api.post(`${base_path}/generate-token`, {project_id});
    return response.data;
  },
  async getAllProjectCollaborators(project_id: UniqueId) {
    const response = await api.get(`${base_path}/collaborators/${project_id}`);
    return response.data;
  },
  async revokeProjectAccess(project_id: UniqueId, invitee_id: UniqueId) {
    const response = await api.post(`${base_path}/revoke_project_access`, {project_id, invitee_id});
    return response.data;
  },
  async inviteCollaborator(project_id: UniqueId, invitee_email: Email) {
    const response = await api.post(`${base_path}/send_project_invitation`, {project_id, invitee_email});
    return response.data;
  },
  async acceptProjectInvitation(token: string) {
    const response = await api.post(`${base_path}/accept_project_invitation`, {token});
    return response.data;
  },
};

export default projectAPI;
