import { useToast } from 'vue-toast-notification';
const $toast = useToast();

export function copyContent(text: string) {
    try {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
            }).catch(err => {
                console.error('Clipboard API error:', err);
                fallbackCopy(text);
            });
        } else {
            fallbackCopy(text);
        }
    } catch (err) {
        console.error('Failed to copy content:', err);
        $toast.error('Failed to copy content');
    }
}

function fallbackCopy(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Fallback copy error:', err);
        $toast.error('Failed to copy content');
    }
    document.body.removeChild(textarea);
}