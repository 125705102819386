<template>
  <div>
    <a-divider>Collaborators</a-divider>

    <a-list v-if="project && project.collaborators" :data-source="project.collaborators" item-layout="horizontal"
      class="collaborators-list">
      <template #renderItem="{ item }">
        <a-list-item class="collaborator-item">
          <a-list-item-meta>
            <template #avatar>
              <a-avatar :src="item.avatar" :alt="item.name || item.email">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
            </template>
            <template #title>
              <span class="collaborator-name">{{ item.name || '' }}</span>
            </template>
            <template #description>
              <a class="collaborator-email">{{ item.email }}</a>
            </template>
          </a-list-item-meta>

          <!-- Popconfirm for Delete -->
          <a-popconfirm title="Are you sure you want to delete this collaborator?" @confirm="removeCollaborator(item)"
            ok-text="Yes" cancel-text="No">
            <DeleteOutlined
              class="text-gray-400 cursor-pointer hover:text-red-500 hover:scale-110 transition-all duration-200" />
          </a-popconfirm>
        </a-list-item>
      </template>
    </a-list>

    <a-empty v-else description="No collaborators found" class="empty-state" />

    <div class="flex items-center justify-end">
      <invite-button />
    </div>
  </div>
</template>

<script setup>
import InviteButton from './InviteButton.vue';
import Project from '@/domain/project/Project';
import { UserOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { useRevokeProjectAccess } from '@/application/revokeProjectAccess';
const { revoke } = useRevokeProjectAccess();

const props = defineProps({
  project: Project,
});

const removeCollaborator = (collaborator) => {
  revoke(props.project.id, collaborator.id);
};
</script>
