<template>
  <div class="flex justify-center items-center min-h-screen bg-gray-900 text-white">
    <form class="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full" @submit.prevent="handleRegister">
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-400">Email:</label>
        <input
          type="email"
          id="email"
          v-model="email"
          class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-200"
          required
        />
      </div>
      <div class="mb-4">
        <label for="password" class="block text-sm font-medium text-gray-400">Password:</label>
        <input
          type="password"
          id="password"
          v-model="password"
          class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-200"
          required
        />
      </div>
      <div class="mb-4">
        <label for="password_confirmation" class="block text-sm font-medium text-gray-400">Password Confirmation:</label>
        <input
          type="password"
          id="password_confirmation"
          v-model="password_confirmation"
          class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-200"
          required
          @change="validatePassword"
        />
        <span v-if="passwordErrors.length > 0" class="text-red-500 text-sm mt-2">
          {{ passwordErrors.join(", ") }}
        </span>
      </div>
      <button
        type="submit"
        class="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150"
      >
        Register
      </button>
      <!-- Register link -->
      <router-link
        :to="{ name: 'Login' }"
        class="block text-center text-sm font-mono text-gray-400 hover:text-white mt-6"
      >
        Already have an account? Sign in
      </router-link>
      <div class="my-4 border-t border-gray-600 text-center pt-2 text-gray-400">OR</div>
      <div class="flex flex-col justify-center items-center sm:flex-row">
        <a href="/auth/google" class="mb-2 sm:mb-0 sm:mr-3">
          <button type="button" class="login-with-google-btn w-52 h-11">Sign up with Google</button>
        </a>
        <a href="/auth/github">
          <button
            type="button"
            class="w-52 h-11 py-2.5 px-4 flex justify-center items-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white transition ease-in duration-200 text-sm shadow-md focus:outline-none focus:ring-2 rounded-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792">
              <path
                d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103z"
              ></path>
            </svg>
            Sign up with GitHub
          </button>
        </a>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import debounce from "lodash/debounce";
import { useAuthenticate } from "@/application/authenticate";
import { validatePassword as validatePasswordUtil } from "@/utils/validation";

const email = ref("");
const password = ref("");
const password_confirmation = ref<string>("");
const passwordErrors = ref<string[]>([]);
const router = useRouter();
const { register } = useAuthenticate();

const handleRegister = async () => {
  passwordErrors.value = validatePasswordUtil(password.value, password_confirmation.value);
  if (passwordErrors.value.length > 0) return;
  try {
    await register(email.value, password.value);
    router.push({ name: 'Login' });
  } catch(err: unknown) {
    passwordErrors.value = ["Something went wrong. Please try again."];
  }
};

const validatePassword = debounce(() => {
  passwordErrors.value = validatePasswordUtil(password.value, password_confirmation.value);
}, 300);
</script>

<style scoped>
.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
</style>
