import { createStore } from 'vuex';
// import createPersistedState from "vuex-persistedstate";
import projects from './modules/projects';
import user from './modules/user';
import events from './modules/events';
import store from './modules/store';
import build from './modules/build';
import widgets from './modules/widgets';

export default createStore({
  modules: {
    user,
    projects,
    events,
    store,
    build,
    widgets,
  },
  mutations: {
    resetState(state) {
      state.projects.collaboratorList = [];
      state.projects.projectList = [];
      state.projects.sharedProjectList = [];
    },
  },
  actions: {
    resetStore({ commit }) {
      commit('resetState');
    },
  },
});
