export function validatePassword(password: string, password_confirmation: string) {
    const passwordErrors = []; // Reset errors
    if (password.length < 8) {
        passwordErrors.push(
            'Password must be at least 8 characters long.'
        );
    }
    if (!/\d+/.test(password)) {
        passwordErrors.push('Password must contain a number.');
    }
    if (!/[A-Z]+/.test(password)) {
        passwordErrors.push(
            'Password must contain an upper-case letter.'
        );
    }
    if (!/[a-z]+/.test(password)) {
        passwordErrors.push(
            'Password must contain a lower-case letter.'
        );
    }
    if (!/[#\!\?&@\$%^&*\(\)]+/.test(password)) {
        passwordErrors.push('Password must contain a symbol.');
    }
    if (password !== password_confirmation) {
        passwordErrors.push('Passwords do not match.');
    }

    return passwordErrors;
}