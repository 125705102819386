<template>
  <div class="login-page flex justify-center items-center min-h-screen bg-gray-900 text-white">
    <form @submit.prevent="handleLogin" class="login-form bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md">
      <div class="form-group mb-6">
        <label for="email" class="block text-sm font-mono text-gray-400 mb-2">Email:</label>
        <input id="email" type="email" v-model="email" required
          class="form-control w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-200" />
      </div>
      <div class="form-group mb-6">
        <label for="password" class="block text-sm font-mono text-gray-400 mb-2">Password:</label>
        <a-input-password id="password" v-model:value="password" required
          class="form-control w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-200" />
      </div>
      <!-- Error Message -->
      <p v-if="errorMessage" class="text-red-500 text-sm mb-4">{{ errorMessage }}</p>

      <button type="submit" :disabled="loading"
        class="btn-login w-full py-2 rounded-lg font-mono transition-colors duration-200 bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-400 disabled:text-gray-300 disabled:cursor-not-allowed">
        Login
      </button>
      <router-link :to="{ name: 'ForgotPassword' }"
        class="register-link block text-right text-sm font-mono text-gray-400 hover:text-white mt-6">
        Forgot Password?
      </router-link>
      <router-link :to="{ name: 'Registration' }"
        class="register-link block text-right text-sm font-mono text-gray-400 hover:text-white mt-6">
        Don't have an account? Create one now
      </router-link>
      <a-divider style="border-color: #fff; color: #fff">OR</a-divider>
      <div class="flex flex-col justify-center items-center sm:flex-row">
        <a href="/auth/google" class="mb-2 sm:mb-0 sm:mr-3">
          <button type="button" class="login-with-google-btn w-52 h-11">Sign in with Google</button>
        </a>
        <a href="/auth/github">
          <button type="button"
            class="w-52 h-11 py-2.5 px-4 max-w-md flex justify-center items-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base text-sm shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="mr-2"
              viewBox="0 0 1792 1792">
              <path
                d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z">
              </path>
            </svg>
            Sign in with GitHub
          </button>
        </a>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAuthenticate } from "@/application/authenticate";
import { AxiosError } from "axios";

const email = ref<string>("");
const password = ref<string>("");
const loading = ref<boolean>(false);
const errorMessage = ref<string | null>(null);

const router = useRouter();
const route = useRoute();
const { authenticate } = useAuthenticate();

const handleLogin = async (): Promise<void> => {
  loading.value = true;
  errorMessage.value = null; // Reset error before a new attempt
  try {
    await authenticate(email.value, password.value);

    // Redirect to intended page after login
    const redirectTo = (route.query.redirectTo as string) ?? null;
    if(redirectTo){
      router.push(redirectTo);
    } else {
      router.push({ name: 'ProjectList' });
    }
  } catch (err: unknown) {
    const error = err as AxiosError; // Cast the error to AxiosError

    if (error.response?.status === 500) {
      errorMessage.value = "Server error. Please try again later.";
    } else if (error.response?.data?.message === "wrong_login_or_password") {
      errorMessage.value = "Invalid email or password. Please try again.";
    } else {
      errorMessage.value = "Something went wrong. Please try again.";
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
:deep(.ant-input) {
  background: none;
  color: rgb(229 231 235);
}

:deep(.ant-input-password) {
  background: none;
  color: rgb(229 231 235);
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}
</style>
