import User from "@/domain/user/User";
import { useAuthStorage, useUserStorage } from "../services/storageAdapter";

// Note that the port interfaces are in the _application layer_,
// but their implementation is in the _adapter_ layer.
import { AuthenticationService, AuthStorageService, UserStorageService } from "./ports";
import { useAuth } from "@/services/authAdapter";
import { UserTokenEntity } from "@/domain/user/UserTypes";

export function useAuthenticate() {
  // Usually, we access services through Dependency Injection.
  // Here we can use hooks as a crooked “DI-container”.

  // The use case function doesn't call third-party services directly,
  // instead, it relies on the interfaces we declared earlier.
  const userStorage: UserStorageService = useUserStorage();
  const authStorage: AuthStorageService = useAuthStorage();
  const auth: AuthenticationService = useAuth();

  // Ideally, we would pass a command as an argument,
  // which would encapsulate all input data.
  async function authenticate(email: Email, password: string): Promise<void> {
    const userToken = await auth.login(email, password);
    authStorage.updateUserToken(userToken);

    const user = await auth.getCurrentUserData();
    userStorage.updateUser(user);
  }

  async function authenticateFromToken(userToken: UserTokenEntity): Promise<void> {
    authStorage.updateUserToken(userToken);

    const user = await auth.getCurrentUserData();
    userStorage.updateUser(user);
  }

  async function authenticateBySession(): Promise<void> {
    const user = await auth.getCurrentUserData();
    userStorage.updateUser(user);
  }

  async function register(email: Email, password: string): Promise<void> {
    const user = await auth.register(email, password);
    userStorage.updateUser(user);
  }

  async function sendForgotPasswordEmail(email: Email): Promise<void> {
    auth.forgotPassword(email);
  }

  async function validateForgotPasswordToken(token: string, userId: UniqueId): Promise<void> {
    const userToken = await auth.validateForgotPasswordToken(token, userId);
    authStorage.updateUserToken(userToken);

    const user = await auth.getCurrentUserData();
    userStorage.updateUser(user);
  }

  function logout(): void {
    authStorage.removeUserToken();
  }

  return {
    user: userStorage.user,
    authenticate,
    authenticateFromToken,
    authenticateBySession,
    register,
    logout,
    sendForgotPasswordEmail,
    validateForgotPasswordToken
  };
}