<template>
  <div>
    <a-table :data-source="sharedProjects" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }" ref="ref3">
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e: Event) => setSelectedKeys((e.target as HTMLInputElement).value ? [(e.target as HTMLInputElement).value] : [])"
            @pressEnter="handleSearch(confirm)" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="handleSearch(confirm)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <router-link v-if="record.id" :to="{ name: 'ScreenList', params: { project_id: record.id } }">
            {{ record.name }}
          </router-link>
        </template>

        <template v-if="column.key === 'links'">
          <router-link v-if="record.name" :to="{ name: 'Config', params: { project_id: record.id } }">
            Config
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import { useProjectStorage } from '@/services/storageAdapter';
const { loadAllProjects, sharedProjects } = useProjectStorage();

const handleSearch = (confirm: () => void) => {
  confirm();
};

const handleReset = (clearFilters: (opts : { confirm: boolean }) => void) => {
  clearFilters({ confirm: true });
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    customFilterDropdown: true,
    onFilter: (value: string, record: { name: string }) =>
      record.name.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: 'Links',
    dataIndex: 'links',
    key: 'links',
  },
];

onMounted(() => {
  loadAllProjects();
});

</script>
