import api from '@/utils/axios';

const widgetAPI = {
  async getAllCustomWidgets() {
    const response = await api.get(`/api/admin/v1/custom_widget_list`);
    return response.data;
  },
  async createCustomWidget(name: string, content: string | null) {
    const response = await api.post('/api/admin/v1/custom_widget', { widget: { name, content } })
    return response.data;
  },
  async updateWidget(widget_id: UniqueId, name: string, content: string | null) {
    const response = await api.put(`/api/admin/v1/custom_widget/${widget_id}`, { widget: { name, content } })
    return response.data;
  },
  async deleteWidget(widget_id: UniqueId) {
    const response = await api.delete(`/api/admin/v1/custom_widget/${widget_id}`)
    return response.data;
  },
};

export default widgetAPI;
