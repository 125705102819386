import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import {  ProjectService, ProjectStorageService, UserStorageService } from "./ports";

export function useDeleteProject() {
  const userStorage: UserStorageService = useUserStorage();
  const projectStorage: ProjectStorageService = useProjectStorage();
  const projectService: ProjectService = useProject()
  let project = null;

  async function remove(projectId: UniqueId): Promise<void> {
    const userId = userStorage.user?.value.id;

    if(!userId) throw Error('User not found');

    project = await projectService.remove(projectId);
    projectStorage.deleteById(projectId);
  }

  return {
    project: project,
    remove,
  };
}