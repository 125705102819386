import { Module } from 'vuex';
import User from '@/domain/user/User';

interface UserState {
  user: User | null;
}

const userModule: Module<UserState, any> = {
  namespaced: true,
  actions: {},
  state: (): UserState => ({
    user: null,
  }),
  mutations: {
    setUser(state, user: User) {
      state.user = user;
    },
  },
  getters: {
    getUserAvatar(state): string | null {
      return state.user?.avatar || null;
    },
  },
};

export default userModule;
