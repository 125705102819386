import { AuthenticationService } from "../application/ports";
import authAPI from "@/infra/api/auth";
import userAPI from "@/infra/api/user";

export function useAuth(): AuthenticationService {
    const login = (email: Email, password: string) => {
        return authAPI.login(email, password);
    };
    const register = (email: Email, password: string) => {
        return authAPI.register(email, password);
    };
    const forgotPassword = (email: Email) => {
        authAPI.forgotPassword(email);
    };
    const validateForgotPasswordToken = (token: string, userId: UniqueId) => {
        return authAPI.validateForgotPasswordToken(token, userId);
    };
    const getCurrentUserData = () => {
        return userAPI.getCurrentUserDataFromSession();
    };

    return {
        login,
        register,
        forgotPassword,
        getCurrentUserData,
        validateForgotPasswordToken
    };
}