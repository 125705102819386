import api from '@/utils/axios';

const androidBuildAPI = {
  async generateAndroidBuild(project_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/generate_android_build/${project_id}`);
    return response.data;
  },
  async getBuildStatus(project_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/android_build/status/${project_id}`);
    return response.data;
  },
  async checkBuildExist(project_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/android_build/exist/${project_id}`);
    return response.data;
  },
  async downloadBuildFile(project_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/android_build/file/${project_id}`,
      {
        responseType: 'blob',
      }
    );
    return response.data;
  },
  async getBuildLogs(project_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/android_build/log/${project_id}`);
    return response.data;
  },
};

export default androidBuildAPI;
