<template>
    <div>
      <draggable-component class="dragArea list-group" :list="list" :clone="clone"
        :group="{ name: 'component', pull: 'clone', put: false }" item-key="name">
        <template #item="{ element }">
          <div class="list-group-item w-100">
            <div class="center"><span>{{ element.display_name || element.name }}</span>
              <div v-if="element.memo">Memo: {{ element.memo }}</div>
            </div>
            <FactoryComponent :key="element.id" :componentName="element.name" :nestedComponents="element.nestedComponents"
              :data="element.data"></FactoryComponent>
          </div>
        </template>
      </draggable-component>
    </div>
  </template>
  
  <script>
  import { uuid } from 'vue-uuid';
  import FactoryComponent from './FactoryComponent';
  const component = {
    name: 'ComponentListOld',
    components: {
      FactoryComponent,
    },
    props: {
      list: Array,
    },
    methods: {
      clone(original) {
        const clone = JSON.parse(JSON.stringify(original));
  
        if (clone.nestedComponents && clone.nestedComponents.length > 0) {
          clone.nestedComponents.forEach((nestedComponent) => {
            nestedComponent.id = uuid.v4();
          });
        }
        clone.id = uuid.v4();
        return clone;
      },
    },
  };
  
  export default component;
  </script>
  <style scoped>
  .center {
    text-align: center;
  }
  </style>
  