import { useProject } from "@/services/projectAdapter";
import { useUserStorage } from "../services/storageAdapter";
import { ProjectService, UserStorageService } from "./ports";

export function useInviteCollaborator() {
    const userStorage: UserStorageService = useUserStorage();
    const projectService: ProjectService = useProject()

    async function invite(projectId: UniqueId, invitee_email: Email): Promise<void> {
        const userId = userStorage.user?.value.id;

        if (!userId) throw Error('User not found');

        await projectService.inviteCollaborator(projectId, invitee_email);
    }

    return {
        invite,
    };
}