import { useUserStorage } from "../services/storageAdapter";
import { UserService, UserStorageService } from "./ports";
import { useUser } from "@/services/userAdapter";
import { UserName } from "@/domain/user/UserTypes";

export function useUpdateUserPersonalInfo() {
  const userStorage: UserStorageService = useUserStorage();
  const userService: UserService = useUser();
  let newUser = null;

  async function update(email: Email, name: UserName): Promise<void> {
    const user = userStorage.user?.value;

    if(!user) throw Error('User not found');

    newUser = await userService.updateInfo(email, name);
    userStorage.updateUser(newUser);
  }

  return {
    user: newUser,
    update,
  };
}