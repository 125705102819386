<template>
  <a-divider>ENV</a-divider>

  <div class="flex justify-between pb-2 mb-2" ref="refDisplayName">
    <a-typography-title :level="5">Display Name</a-typography-title>
    <div>
      <a-input-group compact class="!flex">
        <a-input v-model:value="config.system_env.display_name" @change="displayNameSubmit" placeholder="My App" />
      </a-input-group>
    </div>
  </div>

  <div class="flex justify-between pb-2 mb-2" ref="refPackageName">
    <a-typography-title :level="5">Package Name</a-typography-title>
    <div>
      <a-input-group compact class="!flex">
        <a-input v-model:value="config.system_env.package_name" @change="packageNameSubmit"
          placeholder="com.company.my_app" />
      </a-input-group>
    </div>
  </div>

  <div v-for="(env, index) in userEnvForm" :key="index">
    <div class="flex justify-between pb-2 mb-2">
      <a-input class="w-44 h-8" v-model:value="env.name" placeholder="HOST" />
      <div>
        <a-input-group compact class="!flex">
          <a-input v-model:value="env.value" @change="addUserEnv(env)" placeholder="http://localhost" />
          <a-button @click="deleteUserEnv(env)" danger>Remove</a-button>
        </a-input-group>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center">
    <a-button @click="addUserEnvRow" type="primary">Add</a-button>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { debounce } from 'lodash';

export default {
  name: 'EnvironmentConfig',
  emits: ["update-config", "add-env", "delete-env"],
  props: {
    config: Object,
    project_id: String
  },
  setup(props, { emit }) {
    const userEnvForm = ref([]);
    const DEBOUNCE_TIME = 500;

    // Initialize user environment form when config changes
    watch(
      () => props.config.user_env,
      (newUserEnv) => {
        if (newUserEnv) {
          userEnvForm.value = Object.keys(newUserEnv).map((key) => ({
            name: key,
            value: newUserEnv[key],
          }));
        }
      },
      { immediate: true }
    );

    // Handle submitting package name change
    const packageNameSubmit = debounce(() => {
      emit('update-config', {
        name: 'package_name',
        value: props.config.system_env.package_name,
        project_id: props.project_id
      });
    }, DEBOUNCE_TIME);

    // Handle submitting display name change
    const displayNameSubmit = debounce(() => {
      emit('update-config', {
        name: 'display_name',
        value: props.config.system_env.display_name,
        project_id: props.project_id
      });
    }, DEBOUNCE_TIME);

    // Handle adding a new environment variable
    const addUserEnvRow = () => {
      userEnvForm.value.push({ name: '', value: '' });
    };

    // Handle adding environment variable data
    const addUserEnv = debounce((env) => {
      if (!env.name || !env.value) return;
      emit('add-env', env);
    }, DEBOUNCE_TIME);

    // Handle deleting an environment variable
    const deleteUserEnv = (env) => {
      emit('delete-env', env);
      userEnvForm.value = userEnvForm.value.filter((e) => e !== env);
    };

    return {
      userEnvForm,
      packageNameSubmit,
      displayNameSubmit,
      addUserEnvRow,
      addUserEnv,
      deleteUserEnv,
    };
  },
};
</script>