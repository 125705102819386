<template>
    <div class="documentation-container">
        <div class="documentation">
            <header class="header">
                <h1>Event Handlers Documentation</h1>
            </header>

            <section class="content">
                <div v-for="(event, index) in events" :key="index">
                    <h2 :id="event.id">
                        {{ event.name }}
                        <a :href="'#' + event.id" class="anchor-link">🔗</a>
                    </h2>
                    <p>{{ event.description }}</p>
                    <p><strong>Parameters:</strong></p>
                    <ul>
                        <li v-for="(param, paramIndex) in event.parameters" :key="paramIndex">
                            <code>{{ param.name }}</code> - {{ param.description }}
                        </li>
                    </ul>
                    <p><strong>Example:</strong></p>
                    <pre><code>{{ event.example }}</code></pre>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { feEvents } from '../../utils/apiDocs';

export default {
    name: 'EventActionsDocumentation',
    mounted() {
        this.scrollToAnchor();
    },
    methods: {
        scrollToAnchor() {
            if (this.$route.hash) {
                const anchor = document.querySelector(this.$route.hash);
                if (anchor) {
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
    },
    watch: {
        '$route'(to, from) {
            this.scrollToAnchor();
        }
    },
    data() {
        return {
            events: feEvents
        };
    }
};
</script>

<style scoped>
/* Main container */
.documentation-container {
    display: flex;
    justify-content: center;
    padding: 40px;
    background-color: #f0f2f5; /* Light background to contrast with main color */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #333;
}

/* Documentation wrapper */
.documentation {
    max-width: 900px;
    width: 100%;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Header styles */
.header {
    border-bottom: 2px solid #001628; /* Main color for header */
    padding-bottom: 10px;
    margin-bottom: 30px;
}

h1 {
    font-size: 28px;
    color: #001628; /* Main color for the main heading */
    margin: 0;
}

.header p em {
    font-size: 14px;
    color: #666;
}

.header p strong {
    font-weight: bold;
}

/* Content section styles */
.content h2 {
    font-size: 24px;
    color: #001628; /* Main color for section headings */
    border-bottom: 2px solid #001628; /* Underline with main color */
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

h2 a {
    margin-left: 10px;
    font-size: 16px;
    color: #001628; /* Main color for links */
    text-decoration: none;
}

h2 a:hover {
    color: #004d66; /* Darker shade for hover effect */
}

.content p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

ul {
    margin: 0;
    padding-left: 20px;
}

ul li {
    margin-bottom: 8px;
    color: #333; /* Dark text for list items */
}

code {
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
}

pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
}

.anchor-link {
    margin-left: 10px;
    font-size: 14px;
    color: #001628; /* Main color for anchor links */
}

.anchor-link:hover {
    color: #004d66; /* Darker shade for hover effect */
}
</style>
