<template>
  <div class="container">
    <a-divider>Notifications</a-divider>
    <form id="notification-form" @submit.prevent="onSubmit">
      <a-select
        class="mb-3"
        v-model:value="selectedProject"
        style="width: 100%"
        @change="handleChangeProject"
        placeholder="Select project"
        required
      >
        <a-select-option
          v-for="item in projectList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>

      <a-input class="mb-3" type="text" v-model:value="userId" placeholder="User ID" />
      <a-input class="mb-3" type="text" v-model:value="title" placeholder="Notification Title" />
      <a-input class="mb-3" type="text" v-model:value="picture" placeholder="Picture URL" />
      <a-input class="mb-3" type="text" v-model:value="largeIconUrl" placeholder="Large Icon URL" />
      <a-input class="mb-3" type="text" v-model:value="bigLargeIconUrl" placeholder="Big Large Icon URL" />
      
      <a-textarea
        class="mb-3"
        v-model:value="message"
        placeholder="Notification Body"
        :rows="4"
      />

      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        Send Notification
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useProjectStorage } from '@/services/storageAdapter';
import { useSendPushNotification } from '@/application/sendPushNotification';
import PushEvent from '@/domain/event/PushEvent';

const $toast = useToast();
const { loadAllProjects, myProjects, sharedProjects } = useProjectStorage();
const { send } = useSendPushNotification();

const userId = ref('');
const selectedProject = ref(null);
const title = ref('');
const picture = ref('');
const largeIconUrl = ref('');
const bigLargeIconUrl = ref('');
const message = ref('');

const projectList = computed(() => myProjects.value.concat(sharedProjects.value));

const handleChangeProject = (value) => {
  selectedProject.value = value;
};

const validateForm = () => {
  if (!selectedProject.value) {
    $toast.error('Please select a project');
    return false;
  }
  if (!userId.value) {
    $toast.error('Please enter a User ID');
    return false;
  }
  if (!title.value) {
    $toast.error('Please enter a Notification Title');
    return false;
  }
  if (!message.value) {
    $toast.error('Please enter the Notification Body');
    return false;
  }
  return true;
};

const onSubmit = async () => {
  if (validateForm()) {
    const event = new PushEvent(
      userId.value,
      title.value,
      message.value,
      picture.value,
      largeIconUrl.value,
      bigLargeIconUrl.value
    );

    try {
      await send(selectedProject.value, event);
    } catch (error) {
      $toast.error('Oops.. Try again later');
    }
  }
};

onMounted(() => {
  loadAllProjects();
});
</script>
