<template>
  <vue-monaco-editor v-model:value="localCode" :language="language" :theme="theme" :options="editorOptions"
    @mount="handleMount" />
</template>

<script setup>
import { ref, shallowRef, defineProps, watch, defineEmits } from 'vue';

// Define props
const props = defineProps({
  code: {
    type: String,
    default: '// some code...',
  },
  language: {
    type: String,
    default: 'json',
  },
  theme: {
    type: String,
    default: 'vs-dark',
  },
  options: {
    type: Object,
    default: () => ({
      automaticLayout: true,
      formatOnType: true,
      formatOnPaste: true,
      readOnly: false,
      minimap: { enabled: false },
    }),
  },
});

// Define emits
const emit = defineEmits(['update:code', 'code-changed']);

// Local state to manage the code within the editor
const localCode = ref(props.code);
const editorOptions = ref(props.options);
const editorRef = shallowRef();

// Watch for prop changes and update local state accordingly
watch(() => props.code, newValue => {
  localCode.value = newValue;
});

watch(() => localCode.value, newValue => {
  emit('update:code', newValue);
});

// Watch for options changes
watch(() => props.options, newValue => {
  editorOptions.value = newValue;
});

// Handler function to set up the editor instance and apply formatting
const handleMount = editor => {
  editorRef.value = editor;
  // Format the document when the editor mounts
  formatCode();
};

// Function to format the code programmatically
function formatCode() {
  if (editorRef.value) {
    editorRef.value.getAction('editor.action.formatDocument').run();
  }
}
</script>

<style scoped>
/* Styling for the Monaco editor */
.vue-monaco-editor {
  width: 100%;
  height: 800px;
  /* Adjust the height as needed */
}
</style>