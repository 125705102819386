import { UserName } from "@/domain/user/UserTypes";
import { UserService } from "../application/ports";
import userAPI from "@/infra/api/user";

export function useUser(): UserService {
    const sendEmailVerificationEmail = () => {
        return userAPI.sendEmailVerificationEmail();
    };
    const verifyEmail = (token: string) => {
        return userAPI.verifyEmail(token);
    };
    const updateInfo = (email: Email, name: UserName) => {
        return userAPI.updateInfo(email, name);
    };
    const updatePassword = (password: string) => {
        return userAPI.updatePassword(password);
    };

    return {
        sendEmailVerificationEmail,
        verifyEmail,
        updateInfo,
        updatePassword
    };
}