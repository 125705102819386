import api from '@/utils/axios';

const authAPI = {
  async login(email: Email, password: string) {
    const response = await api.post('/api/admin/v1/user/login', { email, password });
    return response.data;
  },
  async register(email: Email, password: string) {
    const response = await api.post('/api/admin/v1/user/register', { email, password });
    return response.data;
  },
  async forgotPassword(email: Email) {
    const response = await api.post('/api/admin/v1/user/forgot-password', { email });
    return response.data;
  },
  async validateForgotPasswordToken(token: string, user_id: UniqueId) {
    const response = await api.post('/api/admin/v1/user/validate-forgot-password-token', { token, user_id });
    return response.data;
  },
};

export default authAPI;
