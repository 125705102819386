import { useUser } from "@/services/userAdapter";
import { useUserStorage } from "../services/storageAdapter";
import { UserService, UserStorageService } from "./ports";

export function useVerifyUserEmail() {
  const userStorage: UserStorageService = useUserStorage();
  const userService: UserService = useUser();
  let newUser = null;

  async function verify(token: string): Promise<void> {
    const user = userStorage.user?.value;

    if(!user) throw Error('User not found');

    await userService.verifyEmail(token);
    newUser = user;
    newUser.email_verified = true;
    userStorage.updateUser(newUser);
  }

  return {
    user: newUser,
    verify,
  };
}