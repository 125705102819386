<template>
  <div class="container">
    <a-divider>Custom Event</a-divider>
    <form id="event-form" class="form" @submit.prevent="onSubmit">
      <a-input class="mb-3" type="text" v-model:value="widgetName" placeholder="Name" />
      <MonacoEditor v-model:code="widgetContent" language="html" />
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
        Save
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import MonacoEditor from '@/components/MonacoEditor'
import { useCustomWidgetStorage } from '@/services/storageAdapter';
import { useUpdateCustomWidget } from '@/application/updateCustomWidget';
import { useToast } from 'vue-toast-notification';

const { loadAllCustomWidgets, widgets } = useCustomWidgetStorage();
const { update } = useUpdateCustomWidget();
const toast = useToast();
const route = useRoute();

const widgetId = route.params.widget_id;
const widgetName = ref(null);
const widgetContent = ref(null);

const widget = computed(() => widgets.value.find((x) => x.id === widgetId));

watchEffect(() => {
  if (widget.value) {
    widgetName.value = widget.value.name;
    widgetContent.value = widget.value.content;
  }
});

const onSubmit = async () => {
  try {
    await update(widgetId, widgetName.value, widgetContent.value);
  } catch (error) {
    toast.error('Opps... Try again');
  }
};

onMounted(async () => {
  await loadAllCustomWidgets();
});
</script>

<style scoped>
.form {
  height: 60vh;
}
</style>
