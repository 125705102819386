<template>
    <div class="flex flex-row justify-between" ref="refMaintenance">
      <a-typography-title :level="5">Maintenance</a-typography-title>
      <a-radio-group 
        v-model:value="config.maintenance.enabled" 
        option-type="button" 
        :options="[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]" 
        @change="maintenanceFormSubmit" 
      />
    </div>
  </template>
  
  <script>
  export default {
    props: ['config'],
    methods: {
      maintenanceFormSubmit() {
        this.$emit('submit-maintenance', this.config.maintenance.enabled);
      }
    }
  };
  </script>
  