import api from '@/utils/axios';

const buildInfoAPI = {
  async getBuildInfo() {
    const response = await api.get('/build-info');
    return response.data;
  },
};

export default buildInfoAPI;
