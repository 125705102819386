<template>
  <div>
    <!-- Auto Update Toggle -->
    <div class="mb-2 flex justify-end items-center">
      <span class="mr-2">Enable Auto Update</span>
      <a-switch v-model:checked="autoUpdateChecked" />
    </div>

    <!-- Table -->
    <a-table :data-source="users" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }">
      <!-- Custom Filter Dropdown -->
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div class="p-2">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            class="w-48 mb-2 block" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(confirm)" />
          <div class="flex space-x-2">
            <a-button type="primary" size="small" class="w-24" @click="handleSearch(confirm)">
              <template #icon>
                <SearchOutlined />
              </template>
              Search
            </a-button>
            <a-button size="small" class="w-24" @click="handleReset(clearFilters)">Reset</a-button>
          </div>
        </div>
      </template>

      <!-- Custom Filter Icon -->
      <template #customFilterIcon="{ filtered }">
        <SearchOutlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <!-- Body Cell Customization -->
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'user_id'">
          <router-link v-if="record.user_id" :to="{ name: 'UserEventLog', params: { user_id: record.user_id } }">
            {{ record.user_id }}
          </router-link>
        </template>
        <template v-if="column.key === 'online_at'">
          {{ timeAgo(record.online_at) }}
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { time2TimeAgo } from "@/utils/time";
import { useEvent } from "@/services/eventAdapter";

const users = ref([]);
const autoUpdateChecked = ref(false);
const activeUsersIntervalID = ref(null);
const { getActiveUsers } = useEvent();

const columns = ref([
  {
    title: "ID",
    dataIndex: "user_id",
    key: "user_id",
    customFilterDropdown: true,
    onFilter: (value, record) => record.id.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Online At",
    dataIndex: "online_at",
    key: "online_at",
  },
  {
    title: "Project Name",
    dataIndex: "project_name",
    key: "project_name",
  },
]);


const handleSearch = (confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters({ confirm: true });
};

const timeAgo = (ts) => {
  return time2TimeAgo(ts);
};

const loadActiveUsers = async () => {
  users.value = await getActiveUsers();
};

onMounted(() => {
  loadActiveUsers();
});

onUnmounted(() => {
  clearInterval(activeUsersIntervalID.value);
});

watch(autoUpdateChecked, (newValue) => {
  if (newValue) {
    activeUsersIntervalID.value = setInterval(loadActiveUsers, 5000);
  } else {
    clearInterval(activeUsersIntervalID.value);
  }
});
</script>
