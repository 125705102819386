export class CustomWidget {
    id: UniqueId;
    name: string;
    content: object | null;
    updated_at: DateTimeString;

    constructor(id: UniqueId, name: string, content: object | null, updated_at: DateTimeString) {
        this.id = id;
        this.name = name;
        this.content = content;
        this.updated_at = updated_at;
    }
}