import Event from '@/domain/event/Event';
import PushEvent from '@/domain/event/PushEvent';
import api from '@/utils/axios';

const baseUrl = '/api/v1/push';

const eventAPI = {
    async sendPushNotification(event: PushEvent, api_token: string) {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-token': api_token,
        };

        const response = await api
            .post(
                `${baseUrl}/notification`,
                {
                    user_id: event.user_id,
                    title: event.title,
                    message: event.message,
                    picture: event.picture,
                    large_icon_url: event.large_icon_url,
                    big_large_icon_url: event.big_large_icon_url,
                },
                { headers }
            );

        return response.data;
    },
    async sendEvent(event: Event, api_token: string) {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-token': api_token,
        };

        const response = await api
            .post(
                `${baseUrl}/event`,
                {
                    event_id: event.event_id,
                    user_id: event.user_id,
                    project_id: event.project_id,
                    screen_id: event.screen_id,
                    action: event.action,
                    payload: event.payload,
                },
                { headers }
            );
        return response.data;

    },
    async getActionList() {
        const response = await api.get('/api/admin/v1/action/list');
        return response.data;
    },
    async getActiveUsers() {
        const response = await api.get('/api/admin/v1/active_users/list');
        return response.data.list;
    },
    async getActiveUserEventLog(user_id: UniqueId) {
        const response = await api.get(`/api/admin/v1/event_log/${user_id}/list`);
        return response.data.message;
    },
};

export default eventAPI;