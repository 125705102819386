import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import {  ProjectService, ProjectStorageService, UserStorageService } from "./ports";
import { ProjectName } from "@/domain/project/ProjectTypes";

export function useUpdateProject() {
  const userStorage: UserStorageService = useUserStorage();
  const projectStorage: ProjectStorageService = useProjectStorage();
  const projectService: ProjectService = useProject()
  let project = null;
  // Ideally, we would pass a command as an argument,
  // which would encapsulate all input data.
  async function update(projectId: UniqueId, name: ProjectName): Promise<void> {
    const userId = userStorage.user?.value.id;

    if(!userId) throw Error('User not found');

    project = await projectService.update(projectId, name);
    projectStorage.addProjects([project]);
  }

  return {
    project: project,
    update,
  };
}