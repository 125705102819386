<template>
  <div>
    <div class="row">
      <a-input v-model:value="newWidgetName" placeholder="New Widget" />
      <a-button @click="saveNewWidget" type="primary">Save</a-button>
    </div>

    <a-table :data-source="widgets" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }">
      <template #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block" @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            " @pressEnter="handleSearch(confirm)" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="handleSearch(confirm)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <SearchOutlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'id'">
          <router-link v-if="record.id" :to="{ name: 'CustomWidget', params: { widget_id: record.id } }">
            {{ record.id }}
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import { useCustomWidgetStorage } from '@/services/storageAdapter';
import { useCreateCustomWidget } from '@/application/createCustomWidget';
import { useToast } from 'vue-toast-notification';

const toast = useToast();
const { loadAllCustomWidgets, widgets } = useCustomWidgetStorage();
const { create } = useCreateCustomWidget();

const newWidgetName = ref('');
const columns = ref([
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) =>
      record.id.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
  },
]);

const handleSearch = (confirm) => confirm();
const handleReset = (clearFilters) => clearFilters({ confirm: true });

const saveNewWidget = async () => {
  try {
    await create(newWidgetName.value, null);
    newWidgetName.value = '';
  } catch (error) {
    toast.error('Opps... Try again');
  }
};

onMounted(async () => {
  await loadAllCustomWidgets();
});
</script>