import { ScreenName } from '@/domain/project/ProjectTypes';
import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const base_path = '/api/admin/v1/configs';

const configAPI = {
  async getConfigs(project_id: UniqueId) {
    const response = await api.get(`${base_path}/${project_id}`);
    return response.data;
  },
  async enableMaintenance(project_id: UniqueId, enabled: boolean) {
    const response = await api.post(`${base_path}/enable_maintenance`, { project_id, enabled });
    return response.data;
  },
  async updateUserEnv(project_id: UniqueId, name: string, value: string) {
    const response = await api.post(`${base_path}/user_env/update`, { project_id, name, value });
    return response.data;
  },
  async removeUserEnv(project_id: UniqueId, name: string) {
    const response = await api.post(`${base_path}/user_env/remove`, { project_id, name });
    return response.data;
  },
  async updateSystemEnv(project_id: UniqueId, name: string, value: string) {
    const response = await api.post(`${base_path}/system_env/update`, { project_id: project_id, name: name, value: value });
    return response.data;
  },
  async requireLogin(project_id: UniqueId, screen_name: ScreenName, enabled: boolean) {
    const response = await api.post(`${base_path}/require_login`, { project_id: project_id, screen_name: screen_name, enabled: enabled });
    return response.data;
  }
};

export default configAPI;
