<template>
    <div class="max-w-5xl mx-auto p-8 bg-white shadow-md rounded-lg mt-10">
        <h1 class="text-4xl font-extrabold text-center text-gray-900 mb-8">🚀 What's New</h1>

        <p class="text-lg text-center text-gray-600 mb-10">
            Stay up to date with the latest improvements and new features.
        </p>

        <div v-for="update in updates" :key="update.version" class="mb-8 border-b pb-6 last:border-none">
            <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
                <div class="flex items-center space-x-4">
                    <h2 class="text-2xl font-semibold text-gray-900">Version {{ update.version }}</h2>
                    <span
                        class="px-3 py-1 text-xs font-semibold rounded-full"
                        :class="getBadgeClass(update.type)"
                    >
                        {{ update.type }}
                    </span>
                </div>
                <span class="text-gray-500 text-sm mt-2 md:mt-0">{{ update.date }}</span>
            </div>

            <ul class="mt-4 space-y-3 text-gray-700 text-lg">
                <li v-for="(change, index) in update.changes" :key="index" class="flex items-start">
                    <span class="text-green-500 mr-2">✔</span> {{ change }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const updates = ref([
    {
        version: '0.18.4',
        date: '2 days ago',
        type: 'Hotfix',
        changes: [
            'Added force parameter to loadScreensByProjectID for conditional loading.',
            'Renamed getUserEventLog to getActiveUserEventLog for clarity.',
            'Updated project version to 0.18.2 and imported onMounted in LoginOauthRedirectPage.',
            'Updated TypeScript to version 5.8.2 and adjusted Node engine requirement.',
            'Simplified getBuildInfo by removing unnecessary try-catch block.',
        ],
    },
    {
        version: '0.18.3',
        date: '2 days ago',
        type: 'Hotfix',
        changes: [
            'Several hotfix merges',
        ],
    },
    {
        version: '0.18.2',
        date: '2 days ago',
        type: 'Hotfix',
        changes: [
            'Several hotfix merges',
        ],
    },
    {
        version: '0.18.1',
        date: '2 days ago',
        type: 'Hotfix',
        changes: [
            'Several hotfix merges',
        ],
    },
    {
        version: '0.18.0',
        date: '2 days ago',
        type: 'Stable',
        changes: [
            'Updated project version to 0.18.0.',
            'Enhanced NestedConstructor to emit change events and update handling logic.',
            'Updated handleKeydown to use Composition API for state management.',
            'Reorganized API imports and migrated store modules to new directory structure.',
            'Migrated Constructor components to new directory structure and replaced deprecated files.',
            'Removed unused constructor module and cleaned up store structure.',
            'Migrated ConstructorPage to Composition API and enhanced component logic.',
            'Simplified type annotations and improved code readability in ConstructorConfigMenu.',
            'Converted ConstructorConfigMenu to Composition API and streamlined clipboard functionality.',
            'Implemented AI widget generation and integrated into ConstructorPage.',
            'Enhanced ConstructorConfigMenu and NestedConstructor with focusedWidget prop and event handling.',
            'Removed isNewEditor prop from ConstructorConfigMenu and simplified logic.',
            'Simplified NestedConstructor component and improved nested components handling.',
            'Updated routing and authentication in ConstructorLayout and ScreenListPage components.',
            'Removed customWidgets API module to streamline codebase.',
            'Converted App component to Composition API and simplified layout computation.',
            'Added useWidgetLibraryStorage function to manage widget library components.',
            'Added WidgetsLibraryStorageService interface to manage ScreenComponents.',
            'Updated custom widget test to include user association.',
            'Removed wormhole module from store and cleaned up imports.',
            'Updated import path for MonacoEditor in CustomWidgetPage component.',
            'Added CustomWidgetPage component for creating and updating custom widgets.',
            'Implemented update functionality for custom widgets and refactored CustomWidgetPage component.',
            'Added CustomWidgetListPage component for managing and displaying custom widgets.',
            'Refactored custom widget API to remove user_id dependency and enhance storage management.',
            'Added BuildInfoStorageService and integrated build info loading in DefaultLayout.',
            'Enhanced ThirdPartyToolsPage layout and improved library listing structure.',
            'Added ScreenStoreDetailsPage component for displaying screen details and installation functionality.',
            'Added generateWidgetWithAI function to screenAPI and updated screenStore to use it.',
            'Added functionality to install screens from the store and updated related components.',
            'Added StorePage component for displaying published screens.',
            'Implemented store module for managing published screens and updated related components.',
            'Commented out unused CustomWidgetList and Examples in SideNav component.',
            'Added success toast notification for event submission in PushEventPage.',
            'Removed unused imports in StorePage component.',
            'Removed unused call to getAllCustomWidgets in ConstructorPage.',
            'Updated createCustomWidget and updateWidget to use string content type.',
            'Updated widget content type to string and removed unused Widget type.',
            'Added widgetAdapter service for managing custom widget operations.',
            'Added CustomWidget class for managing custom widget data.',
            'Replaced AuthenticationService with UserService in email verification logic.',
            'Added WidgetService interface for custom widget management.',
            'Implemented custom widget API and Vuex module for state management.',
            'Added TypeScript types to androidBuild API methods.',
        ],
    },
    {
        version: '0.17.0',
        date: '2 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.17.0.',
            'Added ActiveUser and ActiveUserEvent types, updated event handling to use new API, and refactored ActiveUsersPage and UserEventLogPage components.',
            'Moved ActiveUsersPage component and updated routing.',
            'Added EpochTimeStamp type and refactored event log handling.',
            'Commented out Examples entry in SideNav for removal.',
            'Refactored create action in CustomWidgetController to use current user and updated tests.',
            'Removed updateConfig function from configAPI for cleanup.',
            'Added UserEventLogPage component and updated routing.',
            'Moved PushEventPage to a new directory and updated routing.',
            'Introduced EventTypes and refactored event handling; removed obsolete action and push event APIs.',
            'Changed private send_response functions to public.',
            'Updated authentication logic to check user session before calling authenticateBySession.',
            'Refactored PushEventPage to use Composition API.',
            'Loaded screens by project ID in maintenance mode and screen login requirement functions.',
            'Added Widget type and integrated into ScreenStorageService; updated ExamplesPage.',
            'Removed notification module from store.',
            'Removed LogsPage component and config module.',
            'Removed unused config module from store.',
            'Added ExamplesPage component.',
            'Added documentation pages for API and event handlers.',
            'Added NotificationsPage component.',
            'Simplified event and push notification sending.',
            'Refactored eventAPI to accept PushEvent and Event objects.',
            'Updated NotificationsPage to use PushEvent.',
            'Refactored event handling to use event and push event models.',
            'Removed unused toast notification instance from projects API.',
            'Added EventService interface.',
            'Implemented event and push notification services.',
            'Added event API for sending push notifications and events.',
            'Refactored NotificationsPage component and implemented event model.',
            'Removed debug log from inviteCollaborator function.',
            'Added UserProfilePage component and updated routing.',
            'Refactored user profile handling and integrated user update functionality.',
            'Updated sendEmailVerificationEmail method to remove token parameter.',
            'Migrated VerifyEmailRedirectPage and updated routing.',
            'Refactored email verification process.',
            'Removed unused getScreenByID method and commented out getAllCustomWidgets call in ConstructorPage.',
            'Removed old ConstructorPage component and routes.',
            'Added ScreenConfigPage component with configuration options.',
            'Added publishScreenToStore functionality.',
            'Updated watch handlers in ScreenConfigPage for responsiveness.',
            'Enhanced login configuration handling.',
            'Updated routing and data structures to include project_id, added setScreenLoginRequirement functionality.',
            'Refactored ScreenConfigPage to use <script setup>.',
            'Removed HelloWorld and HomePage components.',
            'Moved NotFound component to Shared directory and updated routing.',
            'Updated import paths for ScreenListPage and ConfigPage.',
            'Added updateAndBroadcastScreen method.',
            'Implemented screen management functionality.',
            'Added TypeScript type annotations to utility functions.',
            'Removed unused Vuex store import in ProjectAcceptInviteRedirectPage.',
            'Cleaned up imports in VerifyEmailRedirectPage and router configuration.',
            'Updated Vuex store mutations to use namespaced module for project management.',
            'Implemented project invitation and acceptance functionality.',
            'Refactored User class constructor to allow nullable name and avatar.',
            'Removed unused revokeProjectAccess action and collaboratorList state.',
            'Refactored User class constructor to include avatar.',
            'Removed console log and commented-out code in ConstructorPage and constructor module.',
            'Updated Screen constructor to accept configs parameter.',
            'Updated ScreenStorageService to include unsavedScreen and persistUnsavedChanges method.',
            'Enhanced ProjectStorageService with deleteProjectCollaborator method.',
            'Implemented revokeProjectAccess functionality.',
            'Added deep and immediate watcher for onPageComponents in ConstructorPage.',
            'Added Screen class and ScreenStorageService interface.',
            'Renamed config property to configs in Project class.',
            'Updated ConstructorPage layout and implemented auto-save.',
            'Fixed project ID reference in removeCollaborator function.',
            'Added collaborator management to Project service.',
            'Added updateSystemEnv method to configAPI.',
            'Added user environment management functions.',
            'Simplified user API methods.',
            'Implemented token-based authentication.',
            'Updated ProjectStorageService to return ComputedRef.',
            'Removed unused project actions and state properties.',
            'Added ConfigPage component.',
            'Handled error case by rendering empty configs.',
            'Removed console.log statements from project-related components.',
            'Added ResetPasswordRedirectPage component.',
            'Implemented validate forgot password token functionality.',
            'Enhanced project configuration management.',
            'Removed UserRepository class.',
            'Implemented project deletion functionality.',
            'Added SharedProjectListPage component.',
            'Streamlined SharedProjectListPage component.',
            'Removed unused userId reference from ProjectListPage component.',
            'Simplified project creation.',
            'Enhanced project and user models.',
            'Added forgot password functionality.',
            'Moved ForgotPasswordPage component.',
            'Removed TypeScript preset from Babel config, added lodash types.',
            'Migrated to TypeScript, updated authentication methods.',
            'Renamed UserEntity to User.',
            'Updated UserEntity methods.',
            'Updated authentication methods to use email and password.',
            'Enhanced user authentication flow.',
            'Migrated to TypeScript, implemented new API services.',
            'Restructured User and UserRepository classes.',
            'Draft: DDD implementation.',
            'Cleaned up EventLogRepository.',
            'Removed EventLog module.',
            'Updated EventLogRepository to use Buffer.',
            'Updated EventLog tests.',
            'Replaced EventLog with EventLogRepository.',
            'Added EventLogRepository.',
        ],
    },
    {
        version: '0.16.1',
        date: '5 weeks ago',
        type: 'Hotfix',
        changes: [
            'Updated version to 0.16.1 and added GEMINI_API_KEY to deployment secrets.',
            'Removed example usage documentation for generate_content function.',
        ],
    },
    {
        version: '0.16.0',
        date: '5 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.16.0.',
            'Added loading indicator and disabled textarea during AI request processing.',
            'Streamlined AI request submission by resetting input fields after processing.',
            'Added AI content generation endpoint and enforced JSON structure requirements.',
            'Implemented AI content generation functionality and integrated with ConstructorPage.',
            'Added GEMINI_API_KEY environment variable to test and deploy workflow.',
            'Enhanced AI input functionality with dynamic textarea and submit handling.',
            'Enhanced ConstructorPage with AI input and tooltip buttons for improved user interaction.',
            'Added Gemini API integration for content generation and configured API key.',
            'Increased HTTP post request receive timeout to 60 seconds.',
        ],
    },
    {
        version: '0.15.1',
        date: '5 weeks ago',
        type: 'Hotfix',
        changes: [
            'Updated unique index on project_invitations to include project_id.',
        ],
    },
    {
        version: '0.15.0',
        date: '5 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.15.0.',
            'Implemented Zlib compression (#38).',
            'Suppressed output of app start command in CI workflow.',
            'Added migration step before starting the app in CI workflow.',
            'Added missing environment variable exports in CI workflow.',
            'Aligned environment variable exports in CI workflow script.',
            'Moved environment variable exports to the script section in CI workflow.',
            'Removed duplicate MIX_ENV variable in CI workflow.',
            'Removed BUILD_DATE variable from CI workflow.',
            'Removed unnecessary blank lines in CI workflow script.',
            'Consolidated environment variable exports in CI workflow.',
            'Removed unnecessary log file creation in CI workflow.',
            'Replaced env with vars for application name in CI workflow.',
            'Streamlined environment variable usage in CI workflow.',
            'Set MIX_ENV to prod for compilation and release in CI workflow.',
        ],
    },
    {
        version: '0.14.0',
        date: '5 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.14.0.',
            'Added user permission verification to project invitation process and enhanced tests.',
            'Simplified test setup by using setup blocks for user and project.',
            'Added user permission verification to enable maintenance functionality.',
            'Added user permission verification to require_login functionality.',
            'Renamed permission verification function for clarity and updated references.',
            'Added compression functionality to OutgoingEvent with corresponding tests.',
            'Implemented compression and decompression utility with telemetry support.',
            'Added user permission verification for removing user environment configurations.',
            'Implemented user permission verification for project configuration updates.',
            'Enhanced user permission checks for updating screen and environment configurations.',
            'Removed UpdateProjectConfig module and related functionality.',
            'Replaced UpdateConfig with UpdateProjectConfig for better project-specific configuration management.',
            'Implemented user permission checks for screen updates and enhanced test coverage.',
            'Implemented user permission checks for project updates.',
            'Migrated ScreenListPage to Composition API and enhanced code readability.',
            'Migrated ProjectListPage to Composition API and improved code structure.',
            'Enhanced logger configuration for production environment.',
            'Converted NestedConstructor to Composition API and improved readability.',
            'Removed unused environment variables from test and deploy workflow.',
        ],
    },
    {
        version: '0.13.0',
        date: '5 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.13.0.',
            'Implemented email confirmation (#36).',
        ],
    },
    {
        version: '0.12.1',
        date: '6 weeks ago',
        type: 'Hotfix',
        changes: [
            'Enhanced 401 error handling to redirect to original path.',
            'Bumped version to 0.12.1.',
        ],
    },
    {
        version: '0.12.0',
        date: '6 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.12.0.',
            'Enhanced email verification tooltip in user profile with verified status indication.',
            'Added tooltip for email verification status in user profile.',
            'Added email verification status to user profile and updated related tests.',
            'Added reset store action and updated project list mutation for improved state management.',
            'Added revoke project access functionality and updated collaborator removal logic.',
            'Implemented revoke project access functionality with updated logic and tests.',
            'Implemented revoke project access functionality with associated deletion of project invitations and access.',
            'Renamed project invite references to project invitation for consistency.',
            'Updated project invitation logic to ensure only unconfirmed invites can be accepted.',
            'Added name attribute to search input and removed mailto from collaborator email link.',
            'Added remove collaborator functionality in project controller and repository.',
            'Added remove collaborator functionality and included user ID in collaborator data.',
            'Added functionality to retrieve and display project collaborators.',
            'Removed redundant test for screen ownership validation.',
            'Removed unused import from ScreenConfigPage.vue.',
            'Updated GlobalDocSearchInput layout for improved responsiveness and styling.',
            'Enhanced project token management by conditionally rendering elements based on project sharing status.',
            'Enhanced login error handling and improved redirection logic.',
            'Implemented collaborator list retrieval by project ID and enhanced related error handling.',
            'Refactored configuration page by modularizing sections into components.',
            'Simplified project name display by removing inline editing functionality.',
            'Added success toast for invitation acceptance and ensured redirection occurs in finally block.',
            'Implemented project invitation acceptance flow and enhanced error handling.',
            'Enhanced project invitation handling with expiration validation and user association.',
            'Updated invite error message and enhanced login redirection handling.',
            'Enhanced collaborator invitation error handling and updated response management.',
            'Added validation to prevent inviting the same user and updated tests.',
            'Implemented collaborator invitation functionality and refactored related components.',
            'Added project invitation functionality and updated related routes and tests.',
            'Renamed getAllProjects to getAllOwnedProjects and added shared projects functionality.',
            'Added user_accessed_projects endpoint and updated project routes.',
            'Removed access_level from project access management and cleaned up related code.',
            'Enhanced project invitation acceptance by adding project access management.',
            'Implemented project invitation acceptance functionality.',
            'Implemented project invitation email functionality and background jobs.',
            'Added project invite model, repository, and factory.',
            'Implemented project access management model and repository.',
        ],
    },
    {
        version: '0.11.3',
        date: '7 weeks ago',
        type: 'Hotfix',
        changes: [
            'Updated error message for expired link and redirected to Login on auth failure.',
        ],
    },
    {
        version: '0.11.2',
        date: '7 weeks ago',
        type: 'Hotfix',
        changes: [
            'Updated version to 0.11.2 and added Mailjet API keys to workflow.',
        ],
    },
    {
        version: '0.11.1',
        date: '7 weeks ago',
        type: 'Hotfix',
        changes: [
            'Updated Mailjet configuration and bumped version to 0.11.1.',
        ],
    },
    {
        version: '0.11.0',
        date: '7 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.11.0.',
            'Added Mailjet API key exports to test and deploy workflow.',
            'Implemented functionality to mark password reset tokens as used and updated related tests.',
            'Added job to remove expired password reset tokens and integrated with email sending process.',
            'Updated forgot password flow to use token and user ID, refactored related components and API calls.',
            'Added app base URL configuration for development environment.',
            'Added token validation for forgot password functionality and enhanced related tests.',
            'Refactored forgot password email handling to include user ID in reset link and updated tests.',
            'Implemented countdown timer for resend button and removed validation code page.',
            'Enhanced password reset email template with improved styling and user greeting.',
            'Implemented forgot password functionality with email validation and error handling.',
            'Configured Swoosh mailer for local development and enhanced forgot password email handling.',
            'Added forgot password functionality with email token sending.',
            'Configured Mailjet adapter for Swoosh mailer with API keys.',
            'Implemented password reset email functionality with HTML template and token link.',
            'Added unit tests for send password reset token email job.',
            'Enhanced password reset email functionality with improved token handling and added tests.',
            'Renamed forgot password email module and job to password reset token email.',
            'Implemented send forgot password email functionality with job scheduling.',
            'Added fake_password_reset_token factory method.',
            'Implemented password reset token model, repository, and factory with tests.',
            'Added migration for password reset tokens table.',
            'Added forgot password and validate code pages with routing.',
        ],
    },
    {
        version: '0.10.5',
        date: '7 weeks ago',
        type: 'Hotfix',
        changes: [
            'Updated serve_file action to return a plain response and bumped version to 0.10.5.',
        ],
    },
    {
        version: '0.10.4',
        date: '7 weeks ago',
        type: 'Stable',
        changes: [
            'Updated version to 0.10.4 in mix.exs.',
            'Added serve_file action to serve a specific text file with 404 handling.',
        ],
    },
    {
        version: '0.10.3',
        date: '7 weeks ago',
        type: 'Stable',
        changes: [
            'Updated version to 0.10.3 in mix.exs.',
            'Removed unused parent_id from NavigateToScreen action and tests.',
            'Improved code formatting and added ordering to config retrieval.',
            'Updated PostgreSQL image to version 16 in docker-compose.yml.',
        ],
    },
    {
        version: '0.10.2',
        date: '7 weeks ago',
        type: 'Hotfix',
        changes: [
            'Added conditional check for project_id and name in ScreenConfigPage.',
            'Updated version to 0.10.2.',
        ],
    },
    {
        version: '0.10.1',
        date: '7 weeks ago',
        type: 'Hotfix',
        changes: [
            'Converted screen_id to integer in update_and_broadcast and bumped version to 0.10.1.',
            'Converted screen_id to integer in update_and_broadcast function.',
        ],
    },
    {
        version: '0.10.0',
        date: '7 weeks ago',
        type: 'Stable',
        changes: [
            'Updated version to 0.10.0.',
            'Streamlined login requirement handling and improved config retrieval.',
            'Added token expiration test and improved time handling in tests.',
            'Updated require_login logic to include screen_name and adjust config handling.',
        ],
    },
    {
        version: '0.10.0',
        date: '7 weeks ago',
        type: 'Stable',
        changes: [
            'Updated version to 0.10.0.',
            'Streamlined login requirement handling and improved config retrieval.',
            'Added token expiration test and improved time handling in tests.',
            'Updated require_login logic to include screen_name and adjust config handling.',
        ],
    },
    {
        version: '0.9.0',
        date: '8 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.9.0.',
            'Implemented installScreenFromStore functionality and integrated modal for project selection.',
            'Added ScreenStoreDetails page and implemented getPublishedScreenById functionality.',
            'Added endpoint to retrieve published screen by ID with corresponding view and tests.',
            'Added install_screen_from_store endpoint to install screens from the store.',
            'Added install_screen_from_store functionality to install screens from the store.',
            'Implemented getAllPublishedScreens action and integrated with StorePage component.',
            'Added published screens list endpoint and related functionality.',
            'Added publish screen to store functionality with modal input for title and description.',
            'Updated body structure in publish_existing_screen_to_store to include only nestedComponents.',
            'Added publish_screen_to_store endpoint and related functionality.',
            'Implemented publish_existing_screen_to_store service and related tests.',
            'Added ScreenStore model and repository, along with related factory and tests.',
            'Updated phoenix_live_view to version 1.0.2 in mix.lock.',
            'Updated Dockerfile and tool versions for Erlang 25 and Elixir 1.18.1.',
            'Improved app shutdown process in test_and_deploy workflow.',
        ],
    },
    {
        version: '0.8.1',
        date: '8 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.8.1 in mix.exs.',
            'Updated token verification method in UserSocket.',
            'Removed build generation section from ConfigPage and updated documentation in GenerateAndroidBuild module.',
        ],
    },
    {
        version: '0.8.0',
        date: '8 weeks ago',
        type: 'Stable',
        changes: [
            'Bumped version to 0.8.0 in mix.exs.',
            'Updated GitHub Actions workflows and added Docker Compose configuration.',
            'Removed DNS resolution check from test and deploy workflow.',
            'Commented out build_and_test job and its dependencies in GitHub Actions workflow.',
            'Updated Elixir and OTP versions in GitHub Actions workflow.',
            'Added test and deploy file.',
            'Refactored release module to ensure database connection before migration; set production database name in runtime configuration.',
            'Updated Dockerfile to expose port 80 and add application name; implemented database creation and migration in release module.',
        ],
    },
    {
        version: '0.7.15',
        date: '3 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Removed deps cache.',
            'Added API token.',
            'Updated styles for constructor.',
            'Improved devcontainer config.',
            'Updated.',
        ],
    },
    {
        version: '0.7.14',
        date: '4 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Improved search.',
            'Improved copy paste logic.',
            'Updated script.',
            'Added BUILD_DATE var.',
        ],
    },
    {
        version: '0.7.13',
        date: '4 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Moved interpolation to top level.',
            'Added test CI for dev branch.',
            'Fixed branch name.',
            'Cleaned up.',
            'Added condition.',
            'Text new pipeline.',
            'Updated main page hide \'getting started page\'.',
            'Updated styles.',
            'Removed pkill.',
            'Killed process just in case.',
            'Added logs.',
            'Fixed version number.',
        ],
    },
    {
        version: '0.7.12',
        date: '4 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Added build info API.',
            'Updated doc.',
        ],
    },
    {
        version: '0.7.11',
        date: '4 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Deployed only main.',
            'Updated styles.',
            'Added scroll.',
            'Added search.',
            'Added what\'s new modal window.',
            'Added ctrlC and ctrlV keys.',
            'Fixed RangeError: Maximum call stack size exceeded.',
            'Udlust build command.',
            'Deployed dev version.',
            'Not rebuild docker every time.',
            'Updated SSH key.',
            'Fixed deploy script.',
            'Fixed.',
            'Updated.',
            'Added migrations.',
            'Fixed android build job.',
            'Fixed close drawer and close popup logic.',
            'Cleaned up.',
            'Added cache for deps.',
            'Test and deploy actions.',
            'Updated envs to fix tests.',
            'Added tests to pipeline.',
            'Updated deploy_vps.yml.',
            'Fixed undefined Mix.env.',
            'Added vars.',
            'Added new deploy action.',
        ],
    },
    {
        version: '0.7.10',
        date: '4 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'On user reconnect sent only events after last event id - last_event_id past of socket obj - update tests.',
            'Fixed buffer - not cache events replayed from buffer.',
            'Added buffer.',
        ],
    },
    {
        version: '0.7.9',
        date: '5 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Used buffer in user channel.',
            'Decreased level of logs for telemetry.',
            'Added logs bo buffer.',
            'Fixed push event page.',
            'Improved logs.',
            'Mxi format.',
            'Improved presence module.',
            'Added test for sorter.',
            'Added 2 modes - keep last x messages - keep first x messages - add sorting.',
            'Moved time to sync part.',
            'Improved cache.',
            'Gettext update.',
            'Buffer (#31).',
            'Fixed tests.',
            'Fixed warnings.',
            'Handled responses other that 200 and 204.',
            'Handled form in async and sync ways.',
            'Fixed update screen event.',
            'Added event_id to all places where used.',
            'Added screen_id and event_id as required fields to event/push api.',
            'Updated retry queue config.',
            'Added note.',
        ],
    },
    {
        version: '0.7.8',
        date: '5 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Separated env to - system_env and user_env - users can add custom env and then use it in templates - system envs for me, that using only for system needs like in android build etc.',
            'Cleaned up.',
            'Updated hare_mq lib.',
        ],
    },
    {
        version: '0.7.7',
        date: '5 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Removed ENVs.',
            'Added interpolation.',
            'Added update env endpoint.',
            'Resolved warning.',
            'Added application service for env.',
            'Added name value list by project id.',
            'Fixed formatting.',
            'Added interpolation module.',
            'Updated deps.',
            'Updated widget list.',
        ],
    },
    {
        version: '0.7.6',
        date: '5 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            '#19 add MangusInputWidget.',
            'Added back btn to constructor page.',
            '#19 add drawer component.',
            '#19 add new widgets.',
            'Deprecated old view.',
            'Added MangusButtonWidget.',
            'Added ngrok to container.',
            'Added INTERMEDIATE_CERTFILE_PATH.',
            'Fixed check_origin.',
            'One more fix.',
            'Moved to https.',
            'Added more to check_origin list.',
            'Updated config http to https.',
        ],
    },
    {
        version: '0.7.5',
        date: '6 months ago',
        type: 'Hotfix',
        changes: [
            'Fixed config.',
        ],
    },
    {
        version: '0.7.4',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Forwarded to https.',
            'Updated path.',
            'Added SSL.',
        ],
    },
    {
        version: '0.7.3',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Fixed styles.',
        ],
    },
    {
        version: '0.7.2',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Fixed name update for screens.',
            'Added auto update btn to log page.',
            'Fixed readme.',
            'Added auto update btn to active users table.',
            'Cleaned up.',
            'Fixed styles.',
            'Updated readme.',
        ],
    },
    {
        version: '0.7.1',
        date: '6 months ago',
        type: 'Hotfix',
        changes: [
            'Fixed typo.',
            'Updated config for dev env.',
        ],
    },
    {
        version: '0.7.0',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Added envs.',
            'Bumped version.',
            'Added github oauth.',
        ],
    },
    {
        version: '0.6.0',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Added oauth 2 (#29).',
            'Added router for OAuth2.',
            'Added OAuth2 strategy for Google.',
            'Added config for oauth2.',
            'Added oauth2 lib.',
            'Added direnv to container.',
            'Updated ShareWidget.',
            'Disabled login btn after press.',
        ],
    },
    {
        version: '0.5.1',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Added share widget.',
        ],
    },
    {
        version: '0.5.0',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Updated config.',
            'Added job_strategy for tests.',
            'Added rabbitmq to pipeline.',
            '21 use rabbitmq instead of oban (#27).',
            'Improved 404 page.',
        ],
    },
    {
        version: '0.4.1',
        date: '6 months ago',
        type: 'Hotfix',
        changes: [
            'Fixed event log api.',
            'Fixed user event log api.',
            'Updated doc.',
        ],
    },
    {
        version: '0.4.0',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            '22 add event log page (#23).',
            '19 user profile page (#20).',
            'Unlocked dashboard route.',
            'Updated ci config.',
            'Restricted fetcing project if user not owner.',
            'Fixed test.',
        ],
    },
    {
        version: '0.3.5',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            '16 add owner chek for open screen by id api (#17).',
            'Updated deploy file.',
        ],
    },
    {
        version: '0.3.4',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
        ],
    },
    {
        version: '0.3.3',
        date: '6 months ago',
        type: 'Hotfix',
        changes: [
            'Bumped version.',
            'Fixed custom widget body.',
        ],
    },
    {
        version: '0.3.2',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Displayed proper name in drop zone.',
            'Added custom widgets to constructor widget list.',
            'Formatting.',
            'Improved naming.',
        ],
    },
    {
        version: '0.3.1',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            'Added config section to open screen response.',
        ],
    },
    {
        version: '0.3.0',
        date: '6 months ago',
        type: 'Stable',
        changes: [
            'Bumped version.',
            '14 page in admin panel to be able configure screen cache settings (#15).',
            'Improved code.',
            'Used embedded schema.',
            'Added screen body structure.',
            'Encapsulated cache into repository module.',
            'Deduplicated same jobs.',
            'Added adaptive styles.',
            'Used one header component.',
            'Fixed styles in constructor after tailwind implementation.',
            'Redesigned.',
            'Added tailwind.',
            'Cleaned up.',
            'Added better initial message.',
            'Updated doc.',
            'Updated example page design.',
            'Updated styles.',
            'Removed 2 components.',
            'Added fullscreen mode.',
            'Added new constructor WIP.',
            'Updated gitignore.',
            'Added new layout add new constructor page.',
            'Added monaco editor add to widget edit page add it to screen edit page.',
            'Added styles to api doc page.',
            'Added more documentation about actions.',
            'Added separate page for mit libs.',
            'Added link to doc update constructor config styles.',
            'Added doc about actions.',
        ],
    },
    {
        version: '0.2.5',
        date: '1 year ago',
        type: 'Stable',
        changes: [
            '**Custom Widget Improvements:** Fixed issues with custom widget body handling.',
            '**Drop Zone Display:** Displayed proper names in the drop zone.',
            '**Custom Widgets in Constructor:** Added custom widgets to the constructor widget list.',
            '**Config Section in Screen Response:** Added a config section to the open screen response.',
            '**Naming Improvements:** Improved naming conventions for better clarity.',
            '**Formatting Fixes:** Addressed various formatting inconsistencies.',
        ],
    },
    {
        version: '0.2.0',
        date: '1 year ago',
        type: 'Stable',
        changes: [
            '**Owner Check for Open Screen API:** Implemented owner check for the open screen by ID API.',
            '**User Profile Page:** Added a user profile page.',
            '**Event Log Page:** Introduced an event log page.',
            '**Dashboard Route Unlocking:** Unlocked the dashboard route.',
            '**Project Fetching Restrictions:** Restricted project fetching to project owners.',
            '**Test Fixes:** Fixed various test failures.',
            '**Deployment File Updates:** Updated deployment configuration files.',
        ],
    },
    {
        version: '0.1.5',
        date: '2 yars ago',
        type: 'Stable',
        changes: [
            '**Custom Widget Body Fix:** Resolved issues with custom widget body content.',
            '**Share Widget Implementation:** Added a share widget feature.',
            '**OAuth 2 Implementation:** Added OAuth 2 support, including Google OAuth.',
            '**RabbitMQ Integration:** Replaced Oban with RabbitMQ for background jobs.',
            '**404 Page Improvement:** Enhanced the 404 error page.',
            '**Event Log API Fixes:** Fixed issues with the event log and user event log APIs.',
        ],
    },
    {
        version: '0.1.0',
        date: '2 years ago', 
        type: 'Stable',
        changes: [
            '**Initial Project Setup:** Established the foundational structure of the project.',
            '**Basic Authentication:** Implemented basic user authentication functionality.',
            '**Project and Screen Management:** Added functionality to create, edit, and delete projects and screens.',
            '**Widget Integration:** Integrated core widgets into the screen building process.',
            '**API Endpoints:** Created basic API endpoints for core functionalities.',
            '**Initial UI Layout:** Developed a preliminary user interface layout.',
            '**Development Environment Setup:** Configured a development environment for efficient coding.',
        ],
    },
])

const getBadgeClass = (type) => {
    switch (type) {
        case 'Latest':
            return 'bg-blue-100 text-blue-700';
        case 'Stable':
            return 'bg-green-100 text-green-700';
        case 'Beta':
            return 'bg-yellow-100 text-yellow-700';
        case 'Initial Release':
            return 'bg-gray-100 text-gray-700';
        default:
            return 'bg-gray-100 text-gray-700';
    }
};
</script>
