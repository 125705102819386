import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import { EventService, ProjectStorageService, UserStorageService } from "./ports";
import { useEvent } from "@/services/eventAdapter";
import PushEvent from "@/domain/event/PushEvent";

export function useSendPushNotification() {
    const userStorage: UserStorageService = useUserStorage();
    const projectStorage: ProjectStorageService = useProjectStorage();
    const eventService: EventService = useEvent();

    async function send(project_id: UniqueId, event: PushEvent): Promise<void> {
        const user = userStorage.user?.value;
        const project = projectStorage.getById(project_id);

        if (!user) throw Error('User not found');
        if (!project.value) throw Error('Project not found');

        await eventService.sendPushNotification(event, project.value.api_token);
    }

    return {
        send,
    };
}