<template>
  <div class="p-6">
    <div class="grid grid-cols-2 gap-6 md:grid-cols-1">
      <div
        v-for="(element, index) in allComponents"
        :key="element.id"
        class="bg-white shadow-md rounded-lg p-5 border border-gray-200"
      >
        <div class="mb-4">
          <h3 class="text-lg font-semibold text-gray-800">{{ element.name }}</h3>
        </div>

        <div class="mb-4">
          <div v-if="editModeIndexes.includes(index)" class="widget">
            <component
              :key="editModeWidgets[index]?.id"
              :is="editModeWidgets[index]?.name"
              :nestedComponents="editModeWidgets[index]?.nestedComponents"
              :data="editModeWidgets[index]?.data"
              :constructor="false"
              class="block"
            />
          </div>
          <div v-else class="widget">
            <component
              :key="element.id"
              :is="element.name"
              :nestedComponents="element.nestedComponents"
              :data="element.data"
              :constructor="false"
              class="widget-block"
            />
          </div>

          <div v-if="showCode[index]" class="mt-4 p-3 bg-gray-100 rounded-md overflow-auto text-sm">
            <pre class="whitespace-pre-wrap break-words">{{ JSON.stringify(element, null, 2) }}</pre>
          </div>
        </div>

        <div class="flex justify-end space-x-3">
          <a-button @click="editMode(index, element)" class="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-md">
            <template #icon>
              <EditOutlined />
            </template>
          </a-button>
          <a-button @click="copyContent(JSON.stringify(element))" class="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-md">
            <template #icon>
              <CopyOutlined />
            </template>
          </a-button>
        </div>

        <div v-if="editModeIndexes.includes(index)" class="mt-4">
          <Vue3JsonEditor
            class="w-full h-56 border border-gray-300 rounded-md"
            v-model="editModeWidgets[index]"
            :expandedOnStart="true"
            @json-change="onJsonChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, getCurrentInstance } from "vue";
import { changeProps } from "find-and";
import { EditOutlined, CopyOutlined } from "@ant-design/icons-vue";
import { Vue3JsonEditor } from "vue3-json-editor";
import { copyContent } from "@/utils/clipboard";
import { useScreenStorage } from "@/services/storageAdapter";

const { widgets } = useScreenStorage();

const editModeIndexes = ref([]);
const editModeWidgets = ref([]);
const showCode = ref([]);

// Get widgets from store and filter by availability
const allComponents = computed(() => {
  return widgets.filter((el) => isComponentAvailable(el.name));
});

// Check if a component exists in the Vue instance
const isComponentAvailable = (componentName) => {
  const instance = getCurrentInstance();
  return !!instance?.appContext.components[componentName];
};

const editMode = (index, element) => {
  const indexInArray = editModeIndexes.value.indexOf(index);
  if (indexInArray === -1) {
    editModeIndexes.value.push(index);
    editModeWidgets.value[index] = { ...element };
  } else {
    editModeIndexes.value.splice(indexInArray, 1);
    editModeWidgets.value.splice(indexInArray, 1);
  }
};

// Handle JSON changes in the editor
const onJsonChange = (data) => {
  editModeWidgets.value = changeProps(editModeWidgets.value, { id: data.id }, { ...data });
};
</script>

<style scoped>
.page-container {
  padding: 20px;
}

.components-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.component-item {
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  max-width: calc(50% - 20px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.component-content {
  flex: 1;
}

.component-code {
  overflow: auto;
  max-width: 100%;
  max-height: 200px;
  background: #fafafa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  white-space: pre-wrap;
}

.json-editor {
  width: 100%;
  max-width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

.component-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media (max-width: 1200px) {
  .component-item {
    flex: 1 1 calc(100% - 20px);
  }
}

@media (max-width: 768px) {
  .component-code {
    font-size: 12px;
  }

  .json-editor {
    height: 150px;
  }
}
</style>
