import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import {  ProjectService, ProjectStorageService, UserStorageService } from "./ports";
import { ProjectName } from "@/domain/project/ProjectTypes";

export function useCreateProject() {
  const userStorage: UserStorageService = useUserStorage();
  const projectStorage: ProjectStorageService = useProjectStorage();
  const projectService: ProjectService = useProject()
  let project = null;

  
  async function create(name: ProjectName): Promise<void> {
    const userId = userStorage.user?.value.id;

    if(!userId) throw Error('User not found');

    project = await projectService.create(name);
    projectStorage.addProjects([project]);
  }

  return {
    project: project,
    create,
  };
}