<template>
  <div class="bg-gray-100 min-h-screen max-w-[800px] mx-auto flex flex-col items-center">
    <a-divider>Push Event</a-divider>

    <form id="event-form" @submit.prevent="onSubmit">
      <!-- Project Selection -->
      <a-select v-model:value="selectedProject" class="mb-4 w-full" placeholder="Select project"
        @change="handleChangeProject">
        <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>

      <!-- Event Selection -->
      <a-select v-model:value="selectedEvent" class="mb-4 w-full" placeholder="Select event"
        @change="handleChangeEvent">
        <a-select-option v-for="item in actions" :key="item.value" :value="item.value">
          {{ item.title }}
        </a-select-option>
      </a-select>

      <!-- Screen Selection -->
      <a-select v-model:value="selectedScreen" class="mb-4 w-full" placeholder="Select screen">
        <a-select-option v-for="item in screenList" :key="item.id" :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>

      <!-- User ID Input -->
      <a-input v-model:value="userId" class="mb-4 w-full" type="text" placeholder="User ID" />

      <!-- Event Body (Payload) -->
      <a-textarea v-model:value="template" class="mb-4 w-full" placeholder="Event Body" :rows="4" />

      <!-- Submit Button -->
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" type="submit">
        Send Event
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useToast } from "vue-toast-notification";
import { useEventStorage, useProjectStorage } from "@/services/storageAdapter";
import Event from "@/domain/event/Event";
import { useSendEvent } from "@/application/sendEvent";

const toast = useToast();
const { loadAllProjects, loadScreensByProjectID, myProjects, sharedProjects, getById } = useProjectStorage();
const { loadActions, actions } = useEventStorage();
const { send } = useSendEvent();

// Reactive State
const userId = ref(null);
const selectedEvent = ref(null);
const selectedProject = ref(null);
const selectedScreen = ref(null);
const template = ref(null);
const screenList = ref([]);

// Computed Properties (Vuex Getters)
const projectList = computed(() => myProjects.value.concat(sharedProjects.value));
const actionListMap = computed(() => {
  const keyValueMap = {};
  actions.value.forEach((obj) => keyValueMap[obj.value] = obj);
  return keyValueMap;
});

// Methods
const handleChangeEvent = (value) => {
  selectedEvent.value = value;
  template.value = JSON.stringify(actionListMap.value[value].template);
};

const handleChangeProject = async (value) => {
  selectedProject.value = value;
  selectedScreen.value = null;

  await loadScreensByProjectID(value);
  const project = getById(value);
  screenList.value = project.value.screens;
};

const onSubmit = async () => {
  if (validateForm()) {
    try {
      const event = new Event(
        null,
        userId.value,
        selectedProject.value,
        selectedScreen.value,
        selectedEvent.value,
        JSON.parse(template.value)
      );
      await send(selectedProject.value, event)
      toast.success("Event sent successfully");
    } catch (error) {
      console.error(error);
    }

  }
};

const validateForm = () => {
  if (!selectedProject.value) return toast.error("Please select a project"), false;
  if (!selectedEvent.value) return toast.error("Please select an event"), false;
  if (!selectedScreen.value) return toast.error("Please select a screen"), false;
  if (!userId.value) return toast.error("Please enter a User ID"), false;
  if (!template.value) return toast.error("Please enter the event body"), false;
  return true;
};

// Fetch Data on Mount
onMounted(async () => {
  await loadAllProjects();
  await loadActions();
});
</script>