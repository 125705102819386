import { Module } from 'vuex';
import { Action } from '@/domain/event/EventTypes';

interface State {
  actions: Action[] | null;
}

const eventModule: Module<State, any> = {
  namespaced: true,
  actions: {},
  state: (): State => ({
    actions: null,
  }),
  mutations: {
    setActions(state, actions: Action[]) {
      state.actions = actions;
    },
  },
  getters: {
    getActions(state): Action[] | null {
      return state.actions;
    },
  },
};

export default eventModule;
