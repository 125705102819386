import { createWebHistory, createRouter } from 'vue-router';
import { useAuthStorage } from '@/services/storageAdapter';
import LoginPage from '@/ui/Auth/LoginPage.vue';
import ProjectListPage from '@/ui/Project/ProjectListPage.vue';
import RegistrationPage from '@/ui/Auth/RegistrationPage.vue';
import ForgotPasswordPage from '@/ui/Auth/ForgotPasswordPage.vue';
import SharedProjectListPage from '@/ui/Project/SharedProjectListPage.vue';
import ConfigPage from '@/ui/Project/Config/ConfigPage.vue';
import ResetPasswordRedirectPage from '@/ui/Auth/ResetPasswordRedirectPage.vue';
import LandingPage from '@/ui/Landing/LandingPage.vue';
import LoginOauthRedirectPage from '@/ui/Auth/LoginOauthRedirectPage.vue';
import ProjectAcceptInviteRedirectPage from '@/ui/Project/ProjectAcceptInviteRedirectPage.vue';
import ScreenListPage from '@/ui/Project/Screen/ScreenListPage.vue';
import NotFound from '@/ui/Shared/NotFound.vue';
import ScreenConfigPage from '@/ui/Project/Screen/ScreenConfigPage.vue';
import VerifyEmailRedirectPage from '@/ui/User/VerifyEmailRedirectPage.vue';
import UserProfilePage from '@/ui/User/UserProfilePage.vue';
import NotificationsPage from '@/ui/Event/NotificationsPage.vue';
import DocsPage from '@/ui/Docs/DocsPage.vue';
import DemoAPIDocsPage from '@/ui/Docs/DemoAPIDocsPage.vue';
import GettingStartedPage from '@/ui/Docs/GettingStartedPage.vue';
import BeActionsDocPage from '@/ui/Docs/BeActionsDocPage.vue';
import FeActionsDocPage from '@/ui/Docs/FeActionsDocPage.vue';
import ExamplesPage from '@/ui/Docs/ExamplesPage.vue';
import PushEventPage from '@/ui/Event/PushEventPage.vue';
import UserEventLogPage from '@/ui/Event/UserEventLogPage.vue';
import ActiveUsersPage from '@/ui/Event/ActiveUsersPage.vue';
import StorePage from '@/ui/Store/StorePage.vue';
import ScreenStoreDetailsPage from '@/ui/Store/ScreenStoreDetailsPage.vue';
import CustomWidgetListPage from '@/ui/CustomWidget/CustomWidgetListPage.vue';
import CustomWidgetPage from '@/ui/CustomWidget/CustomWidgetPage.vue';
import ConstructorPage from '@/ui/Project/Constructor/ConstructorPage.vue';
import WhatsNewPage from '@/ui/Docs/WhatsNewPage.vue';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/login/o/:token/:user_id',
    name: 'LoginOauth',
    component: LoginOauthRedirectPage,
    meta: {
      auth: false,
      layout: 'blank-layout',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: RegistrationPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordRedirect',
    component: ResetPasswordRedirectPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfilePage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/',
    name: 'ProjectList',
    component: ProjectListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/shared-projects',
    name: 'SharedProjectList',
    component: SharedProjectListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  
  {
    path: '/screens/:project_id',
    name: 'ScreenList',
    component: ScreenListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/config/:project_id',
    name: 'Config',
    component: ConfigPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/config/screen/:project_id/:screen_id',
    name: 'ScreenConfig',
    component: ScreenConfigPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/push-event',
    name: 'PushEvent',
    component: PushEventPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/active-users',
    name: 'ActiveUsers',
    component: ActiveUsersPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/event-log/:user_id',
    name: 'UserEventLog',
    component: UserEventLogPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/notification',
    name: 'Notifications',
    component: NotificationsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/constructor/:project_id/:screen_id',
    name: 'Constructor',
    component: ConstructorPage,
    meta: {
      auth: true,
      layout: 'constructor-layout',
    },
  },
  {
    path: '/examples',
    name: 'Examples',
    component: ExamplesPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/docs',
    name: 'Docs',
    component: DocsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/store',
    name: 'Store',
    component: StorePage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'store',
    },
  },
  {
    path: '/store/:id',
    name: 'ScreenStoreDetails',
    component: ScreenStoreDetailsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'store',
    },
  },
  {
    path: '/docs/demo-api',
    name: 'DemoAPI',
    component: DemoAPIDocsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/docs/getting-started',
    name: 'GettingStarted',
    component: GettingStartedPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/docs/be-actions',
    name: 'BeActionsDoc',
    component: BeActionsDocPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/docs/fe-actions',
    name: 'FeActionsDoc',
    component: FeActionsDocPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/custom_widgets',
    name: 'CustomWidgetList',
    component: CustomWidgetListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/custom_widgets/:widget_id',
    name: 'CustomWidget',
    component: CustomWidgetPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/whats-new',
    name: 'WhatsNew',
    component: WhatsNewPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/accept-invite',
    name: 'ProjectAcceptInviteRedirect',
    component: ProjectAcceptInviteRedirectPage,
    meta: {
      auth: true,
      layout: 'blank-layout',
    },
  },
  {
    path: '/verify-email',
    name: 'VerifyEmailRedirect',
    component: VerifyEmailRedirectPage,
    meta: {
      auth: true,
      layout: 'blank-layout',
    },
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: {
      auth: false,
      layout: 'blank-layout',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { token } = useAuthStorage()
  if (!to.meta.auth && token) {
    return next({ name: 'ProjectList' });
  }
  if (to.meta.auth && !token) {
    return next({ name: 'Login', query: { redirectTo: to.fullPath } });
  }
  next();
});

export default router;
