<template>
  <a-input v-model:value="searchComponentName" placeholder="Search..." class="bg-[#1E1E1E] text-white border-none placeholder:text-white"/>
  <div class="flex">
    <!-- Sidebar for Filter Menu -->
    <div class="w-18 mr-1 mt-4">
      <div class="cursor-pointer hover:bg-[#007aff] p-1 rounded"
        :class="{ 'bg-gray-[#007aff]': selectedType === 'layout' }" @click="filterByType('layout')">
        <a-tooltip placement="right" title="Layouts">
          <LayoutOutlined class="text-white text-xl" />
        </a-tooltip>
      </div>
      <!-- <div class="cursor-pointer hover:bg-gray-200"
        :class="{ 'bg-gray-300': selectedType === 'simple' }" @click="filterByType('simple')">
        Simple Components
      </div> -->
      <div class="cursor-pointer hover:bg-[#007aff] p-1 rounded"
        :class="{ 'bg-gray-[#007aff]': selectedType === 'composite' }" @click="filterByType('composite')">
        <a-tooltip placement="right" title="Composite Widgets">
          <MacCommandOutlined class="text-white text-xl" />
        </a-tooltip>

      </div>
      <div class="cursor-pointer hover:bg-[#007aff] p-1 rounded" :class="{ 'bg-gray-[#007aff]': selectedType === '' }"
        @click="filterByType('')">
        <a-tooltip placement="right" title="All">
          <TableOutlined class="text-white text-xl" />
        </a-tooltip>
      </div>
    </div>

    <!-- Main Content Area with Draggable List -->
    <div class="text-white mt-4">
      <draggable-component class="ist-group grid grid-cols-1 sm:grid-cols-3 gap-1" :list="componentList" :clone="clone"
        :group="{ name: 'component', pull: 'clone', put: false }" item-key="name">
        <template #item="{ element }">
          <div
            class="hover:bg-[#007aff] w-full border border-gray-300 rounded-lg shadow-md flex flex-col items-center justify-between p-1">
            <!-- Icon -->
            <div class="text-lg">
              <DesktopOutlined />
            </div>

            <!-- Title -->
            <span class="font-semibold text-xs truncate">{{ (element.display_name || element.name).split('Widget')[0] }}</span>

            <!-- Memo (optional) -->
            <div v-if="element.memo" class="text-xs truncate">
              Memo: {{ element.memo }}
            </div>
          </div>
        </template>
      </draggable-component>
    </div>
  </div>
</template>

<script setup>
import { uuid } from 'vue-uuid';
import { LayoutOutlined, DesktopOutlined, TableOutlined, MacCommandOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';
import { componentListSearch } from '@/infra/store/modules/data';

// Props
const props = defineProps({
  list: Array,
});

// Data
const selectedType = ref('');
const searchComponentName = ref('');

// Clone method
const clone = (original) => {
  const cloned = JSON.parse(JSON.stringify(original));

  if (cloned.nestedComponents && cloned.nestedComponents.length > 0) {
    cloned.nestedComponents.forEach((nestedComponent) => {
      nestedComponent.id = uuid.v4();
    });
  }
  cloned.id = uuid.v4();
  return cloned;
};

// Filter method to update the selected type
const filterByType = (type) => {
  selectedType.value = type;
};

const componentList = computed(() => {
  const searchString = searchComponentName.value?.toLowerCase().trim();

  if (searchString) {
    const results = componentListSearch.search(searchString);
    return results.map(result => props.list.find(item => item.id === result.id));
  }

  if (selectedType.value !== '') {
    return props.list.filter((item) => item.type === selectedType.value);
  }

  return props.list;
});
</script>