import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import { ProjectService, ProjectStorageService, UserStorageService } from "./ports";

export function useRevokeProjectAccess() {
    const userStorage: UserStorageService = useUserStorage();
    const projectService: ProjectService = useProject()
    const projectStorage: ProjectStorageService = useProjectStorage();

    async function revoke(projectId: UniqueId, inviteeId: UniqueId,): Promise<void> {
        const userId = userStorage.user?.value.id;

        if (!userId) throw Error('User not found');

        await projectService.revokeProjectAccess(projectId, inviteeId);
        projectStorage.deleteProjectCollaborator(projectId, inviteeId);
    }

    return {
        revoke,
    };
}