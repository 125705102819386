import { ScreenBody, ScreenName } from '@/domain/project/ProjectTypes';
import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const base_path = '/api/admin/v1/screens';
const update_and_broadcast_path = '/api/admin/v1/screens/update-and-broadcast';

const screenAPI = {
  async createScreen(project_id: UniqueId, name: ScreenName, body: ScreenBody | null) {
    const response = await api.post(base_path, { project_id: project_id, screen: { name, body } });
    return response.data;
  },
  async getAllProjectScreens(project_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/screens_list/${project_id}`);
    return response.data;
  },
  async deleteScreen(screen_id: UniqueId) {
    const response = await api.delete(`${base_path}/${screen_id}`);
    return response.data;
  },
  async updateScreen(screen_id: UniqueId, name: ScreenName, body: ScreenBody | null) {
    const response = await api.put(`${base_path}/${screen_id}`, { screen: { name, ...body } });
    return response.data;
  },
  async updateAndBroadcastScreen(screen_id: UniqueId, name: ScreenName, body: ScreenBody | null) {
    const response = await api.put(`${update_and_broadcast_path}/${screen_id}`, { screen: { name, ...body } });
    return response.data;
  },
  async publishScreenToStore(screen_id: UniqueId, title: string, description: string) {
    const response = await api.post(`${base_path}/publish_screen_to_store`, { id: Number(screen_id), title: title, description: description });
    return response.data;
  },
  async getAllPublishedScreens() {
    const response = await api.get(`/api/admin/v1/screens/published`);
    return response.data;
  },
  async getPublishedScreenById(screen_id: UniqueId) {
    const response = await api.get(`/api/admin/v1/screens/published/${screen_id}`);
    return response.data;
  },
  async installScreenFromStore(screen_id: UniqueId, project_id: UniqueId) {
    const response = await api.post(`${base_path}/install_screen_from_store`, { id: screen_id, project_id: project_id });
    return response.data;
  },
  async generateWidgetWithAI(prompt: string) {
    const response = await api.post(`${base_path}/generate_ai_content`, { prompt: prompt });
    return response.data.message;
  },
};

export default screenAPI;
