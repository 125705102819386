<template></template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { onMounted } from 'vue';
import { useAcceptProjectInvitation } from '@/application/acceptProjectInvitation';

const router = useRouter();
const route = useRoute();
const $toast = useToast();
const { accept } = useAcceptProjectInvitation();

onMounted(async () => {
    const token = route.query.token;

    if (!token) {
        $toast.error('Link is invalid');
        router.replace({ name: 'Login' });
        return;
    }

    try {
        await accept(token);
        $toast.success('Invitation accepted successfully');
        router.replace({ name: 'SharedProjectList' });
    } catch (error) {
        $toast.error(error?.message || 'Failed to accept invitation');
        router.replace({ name: 'Login' });
    }
});
</script>
