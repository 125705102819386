import { ScreenBody, ScreenName } from "@/domain/project/ProjectTypes";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import { ProjectService, ProjectStorageService, UserStorageService } from "./ports";
import { useProject } from "@/services/projectAdapter";

export function useUpdateScreen() {
  const userStorage: UserStorageService = useUserStorage();
  const projectService: ProjectService = useProject();
  const projectStorage: ProjectStorageService = useProjectStorage();
  let screen = null;

  
  async function update(project_id: UniqueId, screen_id: UniqueId, name: ScreenName, body: ScreenBody, broadcast: boolean = false): Promise<void> {
    const userId = userStorage.user?.value.id;

    if (!userId) throw Error('User not found');

    if (broadcast) {
      screen = await projectService.updateAndBroadcastScreen(screen_id, name, body);
    } else {
      screen = await projectService.updateScreen(screen_id, name, body);
    }

    projectStorage.addScreens(project_id, [screen]);
  }

  return {
    screen: screen,
    update,
  };
}