export default class PushEvent {
  user_id: UniqueId;
  title: string;
  message: string;
  picture: string | null;
  large_icon_url: string | null;
  big_large_icon_url: string | null;

  constructor(userId: UniqueId, title: string, message: string, picture: string | null, large_icon_url: string | null, big_large_icon_url: string | null) {
    this.user_id = userId;
    this.title = title;
    this.message = message;
    this.picture = picture;
    this.large_icon_url = large_icon_url;
    this.big_large_icon_url = big_large_icon_url;
  }
}