<template>
    <div class="flex items-center justify-end">
        <a-button class="flex items-center justify-center" @click="() => (inviteModalOpen = !inviteModalOpen)"
            type="text">
            <PlusOutlined /> Invite
        </a-button>

        <a-modal v-model:open="inviteModalOpen" width="500px" title="Invite a Collaborator" @ok="handleInviteModalOk"
            @cancel="handleInviteModalCancel" :confirmLoading="loading" centered>
            <div class="space-y-6">
                <!-- Modal Introduction Text -->
                <div class="text-sm text-gray-600">
                    <p>Please enter the email address of the person you'd like to invite to collaborate.</p>
                    <p class="mt-2">
                        Make sure the email address is correct, as we'll send an invitation to join the project directly
                        to this
                        email.
                    </p>
                </div>

                <!-- Email Input -->
                <div class="flex flex-col space-y-2">
                    <label for="email" class="text-sm font-medium text-gray-700">Email</label>
                    <a-input id="email" v-model:value="email" placeholder="Enter email address" type="email"
                        autocomplete="email"
                        class="rounded-lg border-2 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        :style="{ borderColor: emailErrorMessage ? 'red' : '' }" />
                    <p v-if="emailErrorMessage" class="text-xs text-red-500">{{ emailErrorMessage }}</p>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useInviteCollaborator } from '@/application/inviteCollaborator';
import { AxiosError } from 'axios';

const route = useRoute();
const { invite } = useInviteCollaborator();
const inviteModalOpen = ref(false);
const email = ref('');
const loading = ref(false);
const emailValid = ref(false);
const emailErrorMessage = ref('');
const projectId = Array.isArray(route.params.project_id)
    ? route.params.project_id[0]
    : route.params.project_id;

// Validate email input
const validateEmail = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email.value)) {
        emailErrorMessage.value = 'Please enter a valid email address';
        emailValid.value = false;
    } else {
        emailErrorMessage.value = '';
        emailValid.value = true;
    }
};

const handleInviteModalOk = async () => {
    validateEmail();
    if (emailValid.value) {
        loading.value = true;
        try {
            await invite(projectId, email.value);
        } catch (err: unknown) {
            const error = err as AxiosError;
            handleError(error);
        } finally {
            loading.value = false;
        }
    }
};

const handleInviteModalCancel = () => {
    inviteModalOpen.value = false;
    email.value = ''; // Clear the email input on cancel
    emailErrorMessage.value = ''; // Reset the error message
};

const handleError = (error: AxiosError) => {
    const data = error.response?.data as { message: string };
    
    if (data.message === ":already_persisted") {
        emailErrorMessage.value = 'The email has already been sent';
    } else if (data.message === ":invitee_email_not_verified") {
        emailErrorMessage.value = 'The invitee email is not verified';
    } else if (data.message === ":user_and_invitee_same") {
        emailErrorMessage.value = 'You cannot invite yourself';
    } else if (data.message === ":user_not_found") {
        emailErrorMessage.value = 'The invitee email is not found';
    } else {
        inviteModalOpen.value = false;
        email.value = '';
    }
}

</script>