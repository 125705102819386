<template>
  <MonacoEditor v-model:code="currentConfiguredWidgetString" ref="monacoEditor" />
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, defineProps, defineEmits } from 'vue';
import { uuid } from 'vue-uuid';
import { copyContent } from '@/utils/clipboard';
import { insertObjectAfter } from 'find-and';
import MonacoEditor from '@/components/MonacoEditor'

// Define Props
const props = defineProps({
  focusedWidget: {
    type: [Object, null],
    required: true,
  },
  list: {
    type: Array,
    required: true,
  },
});

// Define Emits
const emit = defineEmits(['update:list']);

// Define Reactive Variables
const isEditorFocused = ref(false);
const currentConfiguredWidgetString = computed({
  get() {
    return props.focusedWidget ? JSON.stringify(props.focusedWidget, null, 4) : "// Click on any component to configure it";
  },
  set(value) {
    if (isJsonString(value)) {
      const parsedValue = JSON.parse(value);
      update(parsedValue);
    }
  },
});

// Define a ref for MonacoEditor
const monacoEditor = ref(null);

// Handle Mouse Enter / Leave for the Monaco Editor
const handleMouseEnter = () => {
  isEditorFocused.value = true;
};

const handleMouseLeave = () => {
  isEditorFocused.value = false;
};

// Handle Keydown Events for Copy/Paste
const handleKeydown = (event) => {
  if (isEditorFocused.value) return;
  if (event.ctrlKey || event.metaKey) {
    if (event.key === 'c') {
      handleCopy();
      event.preventDefault();
    } else if (event.key === 'v') {
      handlePaste();
      event.preventDefault();
    }
  }
};

// Handle Copy Action
const handleCopy = () => {
  copyContent(currentConfiguredWidgetString.value);
};

// Handle Paste Action
const handlePaste = () => {
  navigator.clipboard.readText().then((value) => {
    const current = JSON.parse(currentConfiguredWidgetString.value);
    const copied = JSON.parse(value);
    if (copied && copied.id) {
      copied.id = uuid.v4();
    }
    const newList = insertObjectAfter(props.list, { id: current.id }, copied);
    emit('update:list', newList);
  });
};

// Change Properties of Components
const changeProps = (components, idProps, newProps) => {
  if (!Array.isArray(components) || components.length === 0) {
    return components;
  }

  return components.map((component) => {
    if (component.id === idProps.id) {
      return { ...component, ...newProps };
    }

    if (component.nestedComponents && Array.isArray(component.nestedComponents)) {
      return {
        ...component,
        nestedComponents: changeProps(component.nestedComponents, idProps, newProps),
      };
    }

    return component;
  });
};

// Check if a string is a valid JSON
const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
};

// Update Components Based on New Value
const update = (value) => {
  try {
    const newComponentList = changeProps(props.list, { id: value.id }, value);
    emit('update:list', newComponentList);
  } catch (error) {
    console.error(error);
  }
};

// Lifecycle Hooks
onMounted(() => {
  const editorContainer = monacoEditor.value?.$el;
  if (editorContainer) {
    editorContainer.addEventListener('mouseenter', handleMouseEnter);
    editorContainer.addEventListener('mouseleave', handleMouseLeave);
  }

  window.addEventListener('keydown', handleKeydown);
});

onBeforeUnmount(() => {
  const editorContainer = monacoEditor.value?.$el;
  if (editorContainer) {
    editorContainer.removeEventListener('mouseenter', handleMouseEnter);
    editorContainer.removeEventListener('mouseleave', handleMouseLeave);
  }

  window.removeEventListener('keydown', handleKeydown);
  emit('update:list', []); // Optionally reset list or do something else
});
</script>

<style scoped>
.textarea {
  width: 100%;
  min-height: 40em;
}

.vscode {
  height: 100%;
}
</style>
