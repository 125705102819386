<template>
  <div v-if="screen" class="p-6">
    <!-- App Header -->
    <div class="flex items-center justify-between mb-4">
      <h1 class="text-2xl font-bold">{{ screen.title }}</h1>
      <div>
        <img src="https://placehold.co/100" alt="App Icon" class="rounded-lg" />
      </div>
    </div>

    <!-- Rating & Downloads -->
    <div class="flex space-x-8 mb-4">
      <div class="flex items-center text-yellow-500">
        ★ {{ rating }}
        <span class="text-gray-500 ml-1">({{ reviews }}k reviews)</span>
      </div>
      <div class="text-gray-500">
        {{ downloads }}k Downloads
      </div>
    </div>

    <!-- Install Button & Modal -->
    <div class="flex justify-between mb-4">
      <button @click="showModal"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Install
      </button>

      <a-modal :open="modalOpen" title="Select a Project" @ok="installScreenFromStore" @cancel="modalHandleCancel"
        width="500px">
        <div class="mb-4">
          <a-select class="mb-3 w-full" v-model:value="selectedProject" @change="handleChangeProject"
            placeholder="Select a Project">
            <a-select-option v-for="item in myProjects" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <p class="text-sm text-gray-500 mt-2">Please select the project you'd like to work with.</p>
        </div>
      </a-modal>
    </div>

    <!-- Description -->
    <div class="mb-4">
      <p class="text-gray-700">{{ screen.description }}</p>
    </div>

    <!-- Screenshots -->
    <div class="mb-4">
      <h3 class="text-lg font-semibold mb-2">Screenshots</h3>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2">
        <div v-for="screenshot in screenshots" :key="screenshot.id" class="rounded overflow-hidden">
          <img :src="screenshot.url" alt="Screenshot" class="w-full h-auto rounded">
        </div>
      </div>
    </div>
  </div>

  <div v-else class="text-center p-6">Loading...</div>
</template>

<script setup>
import { useInstallScreenFromStore } from '@/application/installScreenFromStore';
import { useProjectStorage, useStoreStorage } from '@/services/storageAdapter';
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';

const toast = useToast();
const route = useRoute();
const { loadAllProjects, myProjects } = useProjectStorage();
const { loadAllPublishedScreens, screens } = useStoreStorage();
const { install } = useInstallScreenFromStore();

const modalOpen = ref(false);
const selectedProject = ref(null);
const screen_id = route.params.id;

const screen = computed(() => screens.value.find(x => x.id === screen_id));

onMounted(() => {
  loadAllPublishedScreens();
  loadAllProjects();
});

// Fake Ratings & Downloads
const rating = computed(() => (Math.random() * (5 - 4) + 4).toFixed(1));
const reviews = computed(() => Math.floor(Math.random() * 100));
const downloads = computed(() => Math.floor(Math.random() * 600));

// Screenshots
const screenshots = [
  { id: 1, url: "https://placehold.co/200x400" },
  { id: 2, url: "https://placehold.co/200x400" },
  { id: 3, url: "https://placehold.co/200x400" },
];

// Modal Handlers
const showModal = () => { modalOpen.value = true; };
const modalHandleCancel = () => { modalOpen.value = false; };
const handleChangeProject = (value) => { selectedProject.value = value; };

// Install Action
const installScreenFromStore = async () => {
  try {
    await install(screen_id, selectedProject.value);
    toast.success('Installed');
  } catch (error) {
    toast.error('Opps... Try again');
  }
  modalHandleCancel();
};
</script>
