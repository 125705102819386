import { UserName } from '@/domain/user/UserTypes';
import api from '@/utils/axios';

const userAPI = {
  async get() {
    const response = await api.get('/api/admin/v1/user');
    return response.data;
  },
  async getCurrentUserDataFromSession() {
    const response = await api.get('/api/admin/v1/user/session');
    return response.data;
  },
  async updateInfo(email: Email, name: UserName) {
    const response = await api.post('/api/admin/v1/user/update', { user: { email, name } });
    return response.data;
  },
  async updatePassword(password: string) {
    const response = await api.post('/api/admin/v1/user/update_password', { user: { password } });
    return response.data;
  },
  async verifyEmail(token: string) {
    const response = await api.post('/api/admin/v1/user/verify_email', { token });
    return response.data;
  },
  async sendEmailVerificationEmail() {
    const response = await api.post('/api/admin/v1/user/send_email_verification');
    return response.data;
  },
};

export default userAPI;
