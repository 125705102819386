<template>
  <div>
    <div class="flex pb-2">
      <a-input name="name" type="text" v-model:value="newProjectName" placeholder="New Project" ref="ref1"
        class="mr-1" />
      <a-button @click="handleSave" type="primary" ref="ref2">Save</a-button>
    </div>
    <a-table :data-source="myProjects" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }" ref="ref3">
      <template #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(confirm)" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="handleSearch(confirm)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <div class="editable-cell">
            <div v-if="editableProject[record.id]" class="editable-cell-input-wrapper">
              <a-input v-model:value="editableProject[record.id].name" @pressEnter="handleUpdate(record)" />
              <check-outlined class="editable-cell-icon-check" @click="handleUpdate(record)" />
            </div>
            <div v-else class="editable-cell-text-wrapper">
              <router-link v-if="record.id" :to="{ name: 'ScreenList', params: { project_id: record.id } }">
                {{ record.name }}
              </router-link>
              <edit-outlined class="editable-cell-icon" @click="edit(record)" />
            </div>
          </div>
        </template>
        <template v-if="column.key === 'action'">
          <a-button @click="handleRemove(record)">Remove</a-button>
        </template>
        <template v-if="column.key === 'links'">
          <router-link v-if="record.name" :to="{ name: 'Config', params: { project_id: record.id } }">
            Config
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
  <a-tour v-model:current="current" :open="open" :steps="steps" @close="handleOpen(false)" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { SearchOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import { useProjectStorage } from '@/services/storageAdapter';
import { useCreateProject } from '@/application/createProject';
import { useUpdateProject } from '@/application/updateProject';
import { useDeleteProject } from '@/application/deleteProject';
// Refs
const ref1 = ref(null);
const ref2 = ref(null);
const ref3 = ref(null);
const searchInput = ref(null);
const current = ref(0);
const open = ref(false);
const { loadAllProjects, myProjects } = useProjectStorage();
const { create } = useCreateProject();
const { update } = useUpdateProject();
const { remove } = useDeleteProject();
// Reactive state
const newProjectName = ref('');
const editableProject = ref({});
// Columns definition for table
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) =>
      record.name.toString().toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: 'Links',
    dataIndex: 'links',
    key: 'links',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
  }
];

// Steps for Tour Component
const steps = [
  {
    title: 'Create a New Project',
    description:
      'Enter the name of your new project in the input field and click "Save" to create it.',
    target: () => ref1.value?.$el,
  },
  {
    title: 'Save the Project',
    description:
      'After entering the project name, click this button to save your new project. Make sure you have entered a name before saving.',
    target: () => ref2.value?.$el,
  },
  {
    title: 'Manage Your Projects',
    description:
      'This table lists all your projects. You can search, edit, or remove projects directly from here. Use the filter icon to search by specific columns.',
    target: () => ref3.value?.$el,
  },
];

// Methods
const handleOpen = (val) => {
  if (!val) {
    localStorage.setItem('tourCompleted-project-list-page', 'true');
  }
  open.value = val;
};

const handleSave = async () => {
  await create(newProjectName.value);
  newProjectName.value = '';
};

const handleUpdate = async (project) => {
  const updated = editableProject.value[project.id];
  await update(project.id, updated.name)
  delete editableProject.value[project.id];
};

const handleRemove = async (project) => {
  await remove(project.id);
};

const edit = (project) => {
  editableProject.value[project.id] = {
    original_name: project.name,
    ...project,
  };
};


const handleSearch = (confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters({ confirm: true });
};

// Mount logic
onMounted(() => {
  if (!localStorage.getItem('tourCompleted-project-list-page')) {
    open.value = true;
  }
  loadAllProjects();
});
</script>

<style scoped>
.editable-cell {
  position: relative;
}

.editable-cell .editable-cell-input-wrapper,
.editable-cell .editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell .editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell .editable-cell-icon,
.editable-cell .editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell .editable-cell-icon {
  margin-top: 4px;
  display: none;
}

.editable-cell .editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell .editable-cell-icon:hover,
.editable-cell .editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
