import { useScreenStorage } from "../services/storageAdapter";
import { ScreenStorageService } from "./ports";
import { Component } from "vue";

export function usePersistUnsavedScreen() {
  const screenStorage: ScreenStorageService = useScreenStorage();

  async function persist(screenId: UniqueId, nestedComponents: Component[]): Promise<void> {
    screenStorage.persistUnsavedChanges(screenId, nestedComponents);
  }

  return {
    persist,
  };
}




