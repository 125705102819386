<template>
  <div class="container">
    <CollaboratorsSection v-if="!isShared && project" :project="project" />

    <a-divider>Config</a-divider>

    <MaintenanceSection :config="config" @submit-maintenance="maintenanceFormSubmit" />
    <ProjectTokens :project="project" @generate-token="generateNewAPIToken" :isShared="isShared" />
    <EnvironmentSection
      :config="config"
      :project_id="project_id"
      @update-config="handleUpdateSystemEnv"
      @add-env="handleAddUserEnv"
      @delete-env="handleDeleteUserEnv"
    />

    <div v-if="buildStatus && buildStatus !== 'not_found'" class="row">
      <a-steps
        :current="statusIndexes[buildStatus]"
        :status="statusIndexes[buildStatus] ? '' : 'error'"
        :items="[
          { title: 'Available' },
          { title: 'Scheduled' },
          { title: 'In Progress' },
          { title: 'Completed' },
        ]"
      />
    </div>

    <div v-if="buildExist" class="row">
      <a-typography-title :level="5">Download Build</a-typography-title>
      <a-button type="primary" @click="handleDownloadBuildClick">
        <template #icon><DownloadOutlined /></template>
        Download
      </a-button>
    </div>

    <div v-if="buildStatus && buildStatus !== 'not_found'">
      <a-button type="primary" @click="showModal">Show Logs</a-button>
      <a-modal v-model:open="open" width="1000px" title="Logs" @ok="handleOk" @cancel="handleOk">
        <pre class="modal-logs">{{ buildLogs }}</pre>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
import CollaboratorsSection from "./CollaboratorsSection.vue";
import MaintenanceSection from "./MaintenanceSection.vue";
import ProjectTokens from "./ProjectTokens.vue";
import EnvironmentSection from "./EnvironmentSection.vue";
import { DownloadOutlined } from "@ant-design/icons-vue";

import androidBuildAPI from "../../../infra/api/androidBuild";

import { useRoute } from "vue-router";
import { useToast } from "vue-toast-notification";
import { ref, reactive, onMounted, onUnmounted } from "vue";

import { useProjectStorage } from "@/services/storageAdapter";
import { useRegenerateProjectApiToken } from "@/application/regenerateProjectApiToken";
import { useEnableMaintenanceModeForProject } from "@/application/enableMaintenanceModeForProject";
import { useRemoveUserEnvForProject } from "@/application/removeUserEnvForProject";
import { useUpdateUserEnvForProject } from "@/application/updateUserEnvForProject";
import { useUpdateSystemEnvForProject } from "@/application/updateSystemEnvForProject";

const route = useRoute();
const toast = useToast();
const { loadAllProjects, loadConfigsByProjectID, loadCollaboratorsByProjectID, getById } = useProjectStorage();
const { generate } = useRegenerateProjectApiToken();
const { enableMaintenanceMode } = useEnableMaintenanceModeForProject();
const {removeUserEnv} = useRemoveUserEnvForProject();
const { updateUserEnv } = useUpdateUserEnvForProject();
const { updateSystemEnv } = useUpdateSystemEnvForProject();

const project_id = ref(route.params.project_id);
const open = ref(false);
const buildLogs = ref(null);
const buildStatus = ref(null);
const buildExist = ref(false);
const isShared = ref(false);
const project = getById(project_id.value);
const userEnvForm = ref([]);
let buildStatusIntervalId = null;
let buildLogIntervalId = null;

const statusIndexes = {
  not_found: -1,
  available: 1,
  scheduled: 2,
  executing: 3,
  completed: 4,
};

const config = reactive({
  login: { enabled: false },
  maintenance: { enabled: false },
  system_env: { package_name: null, display_name: null },
  user_env: {},
});

const initConfig = async () => {
  const configData = project.value.configs;
  if (configData) {
    configData.forEach((item) => {
      config[item.name] = item.value;
    });
    if (config.user_env) {
      userEnvForm.value = Object.entries(config.user_env).map(([key, value]) => ({ name: key, value }));
    }
  }
};

const maintenanceFormSubmit = () => {
  enableMaintenanceMode(project_id.value, config.maintenance.enabled);
};

const generateNewAPIToken = () => {
  generate(project_id.value)
};

const showModal = () => {
  open.value = true;
  buildLogIntervalId = setInterval(getBuildLogs, 5000);
};

const handleOk = () => {
  open.value = false;
  clearInterval(buildLogIntervalId);
};

const handleDeleteUserEnv = async (env) => {
  try {
    await removeUserEnv(project_id.value, env.name);
    toast.success('Removed');
  } catch (error) {
    toast.error('Opps... Try again');
  }
};

const handleAddUserEnv = async (env) => {
  if (!env.name || !env.value) return;

  try {
    await updateUserEnv(project_id.value, env.name, env.value);
    toast.success('Saved');
  } catch (error) {
    toast.error('Opps... Try again');
  }
};

const handleUpdateSystemEnv = async (env) => {
  if (!env.name) return;

  try {
    await updateSystemEnv(project_id.value, env.name, env.value);
    toast.success('Saved');
  } catch (error) {
    toast.error('Opps... Try again');
  }
}

const handleGenerateBuildClick = async () => {
  try {
    await androidBuildAPI.generateAndroidBuild(project_id.value);
    buildStatus.value = "available";
    buildStatusIntervalId = setInterval(getBuildStatus, 20000);
    toast.success("Build started");
  } catch (e) {
    toast.error(e.message);
  }
};

const getBuildStatus = async () => {
  try {
    const data = await androidBuildAPI.getBuildStatus(project_id.value);
    buildStatus.value = data?.status;
    if (["completed", "not_found"].includes(buildStatus.value)) {
      if (buildStatus.value === "completed") {
        buildExist.value = true;
      }
      clearInterval(buildStatusIntervalId);
    }
  } catch (e) {
    toast.error(e.message);
  }
};

const getBuildExist = async () => {
  await androidBuildAPI.checkBuildExist(project_id.value);
  buildExist.value = true;
};

const getBuildLogs = async () => {
  buildLogs.value = await androidBuildAPI.getBuildLogs(project_id.value);
};

const handleDownloadBuildClick = async () => {
  try {
    const response = await androidBuildAPI.downloadBuildFile(project_id.value);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${project_id.value}.apk`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    toast.error("File not found");
  }
};

onMounted(async () => {
  await loadAllProjects();
  await loadConfigsByProjectID(project_id.value);
  await loadCollaboratorsByProjectID(project_id.value);

  initConfig();
});

onUnmounted(() => {
  clearInterval(buildStatusIntervalId);
  clearInterval(buildLogIntervalId);
});
</script>

<style scoped>
.modal-logs {
  max-height: 600px;
}
</style>
