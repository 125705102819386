import { uuid } from "vue-uuid";

export default class Event {
  event_id: UniqueId;
  user_id: UniqueId;
  project_id: UniqueId;
  screen_id: UniqueId;
  action: string;
  payload: object | null;

  constructor(event_id: UniqueId, user_id: UniqueId, project_id: UniqueId, screen_id: UniqueId, action: string, payload: object | null) {
    this.event_id = event_id || uuid.v4();
    this.user_id = user_id;
    this.project_id = project_id;
    this.screen_id = screen_id;
    this.action = action;
    this.payload = payload;
  }
}