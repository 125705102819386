import { ScreenBody, ScreenName } from "@/domain/project/ProjectTypes";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import { ProjectService, ProjectStorageService, UserStorageService } from "./ports";
import { useProject } from "@/services/projectAdapter";

export function useCreateScreen() {
  const userStorage: UserStorageService = useUserStorage();
  const projectService: ProjectService = useProject();
  const projectStorage: ProjectStorageService = useProjectStorage();
  let screen = null;

  
  async function create(project_id: UniqueId, name: ScreenName, body: ScreenBody): Promise<void> {
    const userId = userStorage.user?.value.id;

    if (!userId) throw Error('User not found');
    screen = await projectService.createScreen(project_id, name, body);
    projectStorage.addScreens(project_id, [screen]);
  }

  return {
    screen: screen,
    create,
  };
}