import { ScreenComponent } from "../project/ProjectTypes";

export default class PublishedScreen {
  id: UniqueId;
  author_id: UniqueId;
  original_screen_id: Number;
  original_screen_revision: Number;
  title: string;
  description: string;
  nestedComponents: ScreenComponent

  constructor(
    id: UniqueId,
    author_id: UniqueId,
    original_screen_id: Number,
    original_screen_revision: Number,
    title: string,
    description: string,
    nestedComponents: ScreenComponent
  ) {
    this.id = id;
    this.author_id = author_id;
    this.original_screen_id = original_screen_id;
    this.original_screen_revision = original_screen_revision;
    this.title = title;
    this.description = description;
    this.nestedComponents = nestedComponents;
  }
}
