import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import {  ProjectService, ProjectStorageService, UserStorageService } from "./ports";
import { ScreenName } from "@/domain/project/ProjectTypes";

export function useSetScreenLoginRequirement() {
  const userStorage: UserStorageService = useUserStorage();
  const projectStorage: ProjectStorageService = useProjectStorage();
  const projectService: ProjectService = useProject()
  let project = null;

  async function require(projectId: UniqueId, screenName: ScreenName, enabled: boolean = false): Promise<void> {
    const userId = userStorage.user?.value.id;

    if(!userId) throw Error('User not found');

    const config = await projectService.setScreenLoginRequirement(projectId, screenName, enabled);
    await projectStorage.loadScreensByProjectID(projectId, true);

    projectStorage.updateProjectConfig(projectId, config);
    project = projectStorage.getById(projectId)
  }

  return {
    project: project,
    require,
  };
}