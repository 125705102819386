<template>
  <div class="pl-5">
    <div class="dropzone-info" v-if="!list">DROP ZONE</div>

    <draggable-component :list="list" group="component" tag="ul" item-key="name" class="draggable"  @change="handleChange">
      <template #item="{ element }">
        <div class="list-group-item">
          <FactoryComponent @click="emitFocus(element)" :key="element.id" :id="element.id" :memo="element.memo"
            :componentName="element.name" :componentDisplayName="element.display_name"
            :nestedComponents="element.nestedComponents" :data="element.data" :actions="element.actions"
            :styles="element.styles"></FactoryComponent>
          <span class="remove" @click="remove(element.id)">X</span>
          <nested-constructor v-if="element.nestedComponents" :list="element.nestedComponents" @update:list="updateNestedComponents(element.id, $event)" @focus="emitFocus" @change="handleChange"/>
        </div>
      </template>
    </draggable-component>
  </div>
</template>

<script setup>
import FactoryComponent from './FactoryComponent';
import { removeObject } from 'find-and';

const props = defineProps({
  list: Array,
});

const emit = defineEmits(['update:list', 'focus', 'change']);

// Methods
const emitFocus = (element) => {
  emit('focus', element);
};

const emitChange = (e) => {
  emit('change', e);
};

const emitListUpdate = (value) => {
  emit('update:list', value);
  emitChange({changed: value});
};

const handleChange = (e) => {
  emitChange(e);
};

const remove = (event_id) => {
  const newList = removeObject(props.list, { id: event_id });
  emitListUpdate(newList);
};

// Handle updating nested components
const updateNestedComponents = (parentId, updatedNestedList) => {
  const parentComponent = props.list.find(component => component.id === parentId);
  if (parentComponent) {
    parentComponent.nestedComponents = updatedNestedList;
    emitListUpdate([...props.list]);
  }
};
</script>

<style scoped>
.drag-zone {
  background-color: aqua;
}

.list-group-item {
  position: relative;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  background-color: red;
  cursor: pointer;
}

.draggable {
  border: 1px dotted #BDBDBD;
  min-height: 50px;
}

.dropzone-info {
  position: sticky;
  text-align: center;
}
</style>