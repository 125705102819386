import configAPI from "@/infra/api/configs";
import screenAPI from "@/infra/api/screens";
import projectAPI from "@/infra/api/projects";
import { ProjectService } from "../application/ports";
import { ProjectName, ScreenBody, ScreenName } from "@/domain/project/ProjectTypes";

export function useProject(): ProjectService {
    const create = (name: ProjectName) => {
        return projectAPI.createProject(name);
    };
    const update = (project_id: UniqueId, name: ProjectName) => {
        return projectAPI.updateProject(project_id, name);
    };
    const remove = (project_id: UniqueId) => {
        return projectAPI.deleteProject(project_id);
    };
    const generateApiToken = (project_id: UniqueId) => {
        return projectAPI.regenerateAPIToken(project_id);
    };
    const revokeProjectAccess = (project_id: UniqueId, invitee_id: UniqueId) => {
        return projectAPI.revokeProjectAccess(project_id, invitee_id);
    };
    const inviteCollaborator = (project_id: UniqueId, invitee_email: Email) => {
        return projectAPI.inviteCollaborator(project_id, invitee_email);
    };
    const acceptProjectInvitation = (token: string) => {
        return projectAPI.acceptProjectInvitation(token);
    };
    const enableMaintenance = (project_id: UniqueId, enabled: boolean) => {
        return configAPI.enableMaintenance(project_id, enabled);
    };
    const setScreenLoginRequirement = (project_id: UniqueId, screen_name: ScreenName, enabled: boolean) => {
        return configAPI.requireLogin(project_id, screen_name, enabled);
    };
    const updateUserEnv = (project_id: UniqueId, name: string, value: string) => {
        return configAPI.updateUserEnv(project_id, name, value);
    };
    const removeUserEnv = (project_id: UniqueId, name: string) => {
        return configAPI.removeUserEnv(project_id, name);
    };
    const updateSystemEnv = (project_id: UniqueId, name: string, value: string) => {
        return configAPI.updateSystemEnv(project_id, name, value);
    };
    const createScreen = (project_id: UniqueId, name: ScreenName, body: ScreenBody) => {
        return screenAPI.createScreen(project_id, name, body);
    };
    const deleteScreen = (screen_id: UniqueId) => {
        return screenAPI.deleteScreen(screen_id);
    };
    const updateScreen = (project_id: UniqueId, name: ScreenName, body: ScreenBody) => {
        return screenAPI.updateScreen(project_id, name, body);
    };
    const updateAndBroadcastScreen = (project_id: UniqueId, name: ScreenName, body: ScreenBody) => {
        return screenAPI.updateAndBroadcastScreen(project_id, name, body);
    };
    const publishScreenToStore = (screen_id: UniqueId, title: string, description: string) => {
        return screenAPI.publishScreenToStore(screen_id, title, description);
    };
    const installScreenFromStore = (screen_id: UniqueId, project_id: UniqueId) => {
        return screenAPI.installScreenFromStore(screen_id, project_id);
    };
    const generateWidgetWithAI = (prompt: string) => {
        return screenAPI.generateWidgetWithAI(prompt);
    };
    return {
        create,
        update,
        remove,
        createScreen,
        updateScreen,
        deleteScreen,
        updateUserEnv,
        removeUserEnv,
        updateSystemEnv,
        generateApiToken,
        enableMaintenance,
        inviteCollaborator,
        revokeProjectAccess,
        generateWidgetWithAI,
        publishScreenToStore,
        installScreenFromStore,
        acceptProjectInvitation,
        updateAndBroadcastScreen,
        setScreenLoginRequirement,
    };
}