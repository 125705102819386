import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import {  ProjectService, ProjectStorageService, UserStorageService } from "./ports";

export function useRemoveUserEnvForProject() {
  const userStorage: UserStorageService = useUserStorage();
  const projectStorage: ProjectStorageService = useProjectStorage();
  const projectService: ProjectService = useProject()
  let project = null;

  async function removeUserEnv(projectId: UniqueId, name: string): Promise<void> {
    const userId = userStorage.user?.value.id;

    if(!userId) throw Error('User not found');

    const config = await projectService.removeUserEnv(projectId, name);
    projectStorage.updateProjectConfig(projectId, config);
    project = projectStorage.getById(projectId)
  }

  return {
    project: project,
    removeUserEnv,
  };
}