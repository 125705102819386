import { Module } from 'vuex';
import PublishedScreen from '@/domain/store/PublishedScreen';

interface State {
  screensMap:  Map<UniqueId, PublishedScreen>;
}

const eventModule: Module<State, any> = {
  namespaced: true,
  actions: {},
  state: (): State => ({
    screensMap: new Map(),
  }),
  mutations: {
    addScreen(state: State, screen: PublishedScreen) {
      state.screensMap.set(screen.id, screen);
    },
  },
  getters: {
    screensMap(state: State): Map<UniqueId, PublishedScreen> {
      return state.screensMap;
    },
  },
};

export default eventModule;
