import { useProject } from "@/services/projectAdapter";
import { useProjectStorage, useUserStorage } from "../services/storageAdapter";
import {  ProjectService, ProjectStorageService, UserStorageService } from "./ports";

export function useEnableMaintenanceModeForProject() {
  const userStorage: UserStorageService = useUserStorage();
  const projectStorage: ProjectStorageService = useProjectStorage();
  const projectService: ProjectService = useProject()
  let project = null;

  async function enableMaintenanceMode(projectId: UniqueId, enabled: boolean): Promise<void> {
    const userId = userStorage.user?.value.id;

    if(!userId) throw Error('User not found');

    const config = await projectService.enableMaintenance(projectId, enabled);
    await projectStorage.loadScreensByProjectID(projectId, true);

    projectStorage.updateProjectConfig(projectId, config);
    project = projectStorage.getById(projectId)
  }

  return {
    project: project,
    enableMaintenanceMode,
  };
}