<template>
  <div class="max-w-lg mx-auto p-3">
    <a-divider>User Profile</a-divider>

    <!-- Username -->
    <div class="mb-3">
      <a-typography-title :level="5">Name</a-typography-title>
      <div class="flex items-center space-x-2">
        <a-input v-model:value="newUser.name" placeholder="Enter your name" class="flex-grow" />
      </div>
    </div>

    <!-- Email -->
    <div class="mb-6">
      <a-typography-title :level="5">Email</a-typography-title>
      <div class="flex items-center space-x-2">
        <a-input v-model:value="newUser.email" placeholder="Enter your email address" class="flex-grow">
          <template #suffix>
            <a-tooltip v-if="!newUser.email_verified">
              <template #title>Your email is not verified. Click here to send the confirmation email.</template>
              <a class="flex" @click="handleSendEmailVerificationEmail">
                <ExclamationCircleOutlined />
              </a>
            </a-tooltip>
            <a-tooltip v-else>
              <template #title>Your email is verified.</template>
              <CheckCircleOutlined />
            </a-tooltip>
          </template>
        </a-input>
      </div>
    </div>

    <!-- Profile Actions -->
    <div class="flex justify-end space-x-4">
      <a-button type="primary" @click="handleSaveUserInfo">Save</a-button>
    </div>

    <!-- Password -->
    <a-divider>Password Update</a-divider>

    <div ref="refPassword">
      <a-typography-title :level="5">Password</a-typography-title>
      <div class="flex items-center space-x-2">
        <a-input-password v-model:value="newUser.password" placeholder="Enter a new password" class="flex-grow mb-6" />
      </div>

      <a-typography-title :level="5">Password confirmation</a-typography-title>
      <a-input-password v-model:value="newUser.password_confirmation" placeholder="Enter a new password confirmation"
        class="flex-grow mb-6" />
      <span v-if="passwordErrors.length > 0" id="error-message" class="text-red-500 text-sm mt-2">{{
        passwordErrors.join(', ')
        }}</span>
    </div>

    <!-- Profile Actions -->
    <div class="flex justify-end space-x-4">
      <a-button type="primary" @click="handleSaveUserPassword">Update Password</a-button>
    </div>

    <!-- <a-divider>Link Profile</a-divider>
    <div>
      <a-button type="primary">Google</a-button>
      <a-button type="primary">Github</a-button>
    </div> -->
  </div>


</template>

<script setup>
import { validatePassword } from '@/utils/validation';
import { ref, watch } from 'vue';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue';
import { useUserStorage } from '@/services/storageAdapter';
import { useUpdateUserPersonalInfo } from '@/application/updateUserPersonalInfo';
import { useToast } from 'vue-toast-notification';
import { useUser } from '@/services/userAdapter';

const newUser = ref({
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  email_verified: false,
});

const { update } = useUpdateUserPersonalInfo();
const { user } = useUserStorage();
// For these actions, there is no need to create use cases for now.  
// It's much easier to just call it from here.  
const { updatePassword, sendEmailVerificationEmail } = useUser();
const $toast = useToast();
const passwordErrors = ref([]);

watch(user, (_user) => {
  if (!_user) return;
  newUser.value.email = _user.email;
  newUser.value.name = _user.name;
  newUser.value.id = _user.id;
  newUser.value.email_verified = _user.email_verified;
}, { immediate: true });

const handleSaveUserInfo = async () => {
  try {
    await update(newUser.value.email, newUser.value.name);
    $toast.success("Saved");
  } catch (error) {
    $toast.error('Opps... Please try again later');
  }
};

const handleSaveUserPassword = () => {
  passwordErrors.value = validatePassword(newUser.value.password, newUser.value.password_confirmation);

  if (passwordErrors.value.length === 0) {
    updatePassword(newUser.value.password);
  }
};

const handleSendEmailVerificationEmail = () => {
  if (newUser.value.email && !newUser.value.email_verified) {
    sendEmailVerificationEmail();
  }
};
</script>